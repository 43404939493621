import { Component, OnInit } from '@angular/core';
import { UserService } from '../users/user.service';
import { Router } from '@angular/router';
import { ToasterService, ToasterConfig } from 'angular2-toaster';
import { HttpErrorResponse } from '@angular/common/http';
import { User } from '../users/User';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-server-maintenance',
  templateUrl: './server-maintenance.component.html',
  styleUrls: ['./server-maintenance.component.css'],
  providers: [ToasterService]
})
export class ServerMaintenanceComponent implements OnInit {
  Servermaintenance = false;
  public Notes:string="";
  private userService: UserService;
  private router: Router;
  user=new User();
  registerForm: FormGroup;
  private toasterService: ToasterService;
  public toasterconfig: ToasterConfig =
  new ToasterConfig({
    tapToDismiss: true,
    timeout: 3000
  });
  formBuilder: any;
  constructor( _userService: UserService,toasterService: ToasterService,_router: Router) {
    this.toasterService = toasterService;
  this.userService=_userService;
  this.router=_router;
   }

  ngOnInit() {
    const currentUser = new User();
  this.user = Object.assign({}, currentUser);
    this.GetServerMaintenance();
  }
  GetServerMaintenance()
  {
    debugger;
    this.userService.GetServerMaintenance()
    .subscribe((data: any) => {
      console.log(data);
      if (data.IsSuccess) {
        this.Servermaintenance=data.Result.Servermaintenance;
        this.Notes=data.Result.Notes;
      }
    }, (err: HttpErrorResponse) => {
      console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
    });
  }
  ServerChange(Servermaintenance){
    debugger
    if(Servermaintenance==true)
    {
      Servermaintenance=false;
    }
    else
    Servermaintenance=true;

    this.userService.ServerMaintenanceUpdate(this.user.ACEClientId,Servermaintenance,this.Notes)
    .subscribe((data: any) => {
      console.log(data);
      if (data.IsSuccess) {
        this.showSuccess();
        this.GetServerMaintenance();
      }
    }, (err: HttpErrorResponse) => {
      console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
    });

  }
  showSuccess() {
    this.toasterService.pop('success', '', 'Data Updated Successfully !');
  }
  AddDashbordNotes() {
    this.userService.ServerMaintenanceUpdate(this.user.ACEClientId,this.Servermaintenance,this.Notes)
    .subscribe((data: any) => {
      console.log(data);
      if (data.IsSuccess) {
        this.showSuccess();
        this.GetServerMaintenance();
      }
    }, (err: HttpErrorResponse) => {
      console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
    });
    
  }
}
