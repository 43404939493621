import { Component, OnInit, ViewChild } from '@angular/core';
import { ToasterService, ToasterConfig } from 'angular2-toaster';
import { ContentAreasService } from './content-areas.service';
import { ContentAreas } from './content-areas';
import { HttpErrorResponse } from '@angular/common/http';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader'; // Import NgxUiLoaderService
import { Ng2ImgMaxService } from 'ng2-img-max';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-content-areas',
  templateUrl: './content-areas.component.html',
  styleUrls: ['./content-areas.component.css'],
  providers: [ToasterService]
})
export class ContentAreasComponent implements OnInit {
  @ViewChild('infoModal') public infoModal: ModalDirective;
  uploadedImage:File;
  aspectRatio:number = 1.78;
  fixImgSize: number = 800;
  imgSize: boolean = false;
  imgType: boolean = false;
  imgAspectRatio: boolean = false;
  splitChar: number = 100;
  isRequired = true;
  summaryArraysplit: string;
  picture: any;
  fileToUpload: File;
  files: any;
  imgURL: string = '';
  imgRevert: string = '';
  image: string;
  noImage: boolean = true;
  isListView = true;
  Id: number;
  contentAreas: ContentAreas;
  contentAreaRevert: ContentAreas = new ContentAreas();
  contentAreaList: Array<ContentAreas>;
  items: Array<any>;
  pageOfItems: Array<any>;
  pageSize = 10;
  private toasterService: ToasterService;
  private contentAreasService: ContentAreasService;
  private router: Router;
  public message: string = "";
  isPageSize = true;
  public toasterconfig: ToasterConfig =
    new ToasterConfig({
      tapToDismiss: true,
      timeout: 3000
    });
  constructor( public sanitizer: DomSanitizer,private ng2ImgMax: Ng2ImgMaxService,toasterService: ToasterService, _contentAreasService: ContentAreasService, _router: Router, private formBuilder: FormBuilder,private ngxService: NgxUiLoaderService) {
    this.toasterService = toasterService;
    this.contentAreasService = _contentAreasService;
    this.router = _router;
  }

  ngOnInit() {
    this.contentAreas = new ContentAreas();
    this.GetContentAreas();
  }
  ChangeView() {
    this.isListView = this.isListView ? false : true;

  }
  AddNew() {
    this.isRequired = true;
    const currentContentArea = new ContentAreas();
    this.contentAreas = Object.assign({}, currentContentArea);
    this.contentAreaRevert=Object.assign({}, currentContentArea);
    this.noImage = true;
    this.ChangeView();

  }
  showSuccess() {
    this.toasterService.pop('success', '', 'Data saved Successfully !');
  }
  showWarning() {
    this.toasterService.pop('warning', '', 'Data Reset Successfully');
  }

  Save() {
     if(this.imgType || this.imgSize ||  this.imgAspectRatio){
       return false;
     }
    if (this.imgURL != '')
      this.contentAreas.Base64Data = this.imgURL.split(',')[1];
    this.contentAreasService.AddUpdateContentArea(this.contentAreas)
      .subscribe((data: any) => {
        console.log(data);
        if (data.IsSuccess) {

          this.toasterService.pop('success', '', 'ContentArea Added Successfully');
          this.ChangeView();
          this.GetContentAreas();
        }
      }, (err: HttpErrorResponse) => {
        this.message = err.error.Message;
        if (this.message.includes("Cannot insert duplicate key row in object 'dbo.ContentAreas' with unique index 'IX_ContentAreas'."))
          this.toasterService.pop('error', '', 'AreaName Is Already Exist');
        console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
      });
    // }
    // return;
  }
  clone() {
    this.contentAreaRevert = Object.assign({}, this.contentAreas);
    console.log(this.contentAreas);
  }
  Reset() {
    const itemObj = Object.assign({}, this.contentAreaRevert);
    this.contentAreas = itemObj;
    if (this.contentAreas.IconImage == null)
      this.noImage = true;
    if (this.contentAreas.IconImage != null)
      this.imgURL = this.imgRevert;
      this.imgSize = false;
      this.imgType = false;
      this.imgAspectRatio=false;
  }
  GetContentAreas() {
    this.ngxService.start();
    this.contentAreasService.GetContentAreas()
      .subscribe((data: any) => {
        console.log(data);
        if (data.IsSuccess) {
          this.contentAreaList = data.Result;
          this.GetSummary(this.contentAreaList);
          this.items = this.contentAreaList;
          this.ngxService.stop();
        }
      }, (err: HttpErrorResponse) => {
        console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
        this.ngxService.stop();
      });
  }

  selectPhoto(file: FileList) {
    this.picture = file.item(0).name;
    this.fileToUpload = file.item(0);
    const File = this.fileToUpload.name;
    //this.contentAreas.FileName = File.split('.')[0];
    this.files = File.split('.');
    this.contentAreas.FileType = this.files[this.files.length - 1];
    var regex = new RegExp("(jpg|png|gif|jpeg)$");
    if (regex.test(this.contentAreas.FileType.toLowerCase())) {
      this.imgType = false;
      console.log('filetype is correct');
    }
    else {
      this.imgType = true;
    }
    this.noImage = false;
    var totalsize = file.item(0).size;
    var totalSizeKB = totalsize / Math.pow(1024, 1);
    if (totalSizeKB < this.fixImgSize) {
      this.imgSize = false;
    }
    else {
      this.imgSize = true;
    }
    const reader = new FileReader();
    reader.onload = (event: any) => {
      this.imgURL = event.target.result;
      var image = new Image();
      image.src = event.target.result;
      image.onload = () => {
        var inputHeight = image.height;
        var inputWidth = image.width;
        var ratio = inputWidth / inputHeight;
        var n = parseFloat(ratio.toFixed(2));
        let outputWidth = inputWidth;
        let outputHeight = inputHeight;
        // if (n > this.aspectRatio) {
        //     outputWidth = inputHeight * this.aspectRatio;
        // } else if (n < this.aspectRatio) {
            outputHeight = inputWidth / this.aspectRatio;
        //}
       let marginHeightMax=outputHeight+(outputHeight*(10/100));
       let marginHeightMin=outputHeight-(outputHeight*(10/100));

       
        if (marginHeightMin<inputHeight && marginHeightMax>inputHeight) {
          this.imgAspectRatio = false;
        }
        else
        this.imgAspectRatio = true;
       
    
      };
     
    };
    
    // const reader1 = new FileReader();
    // reader1.onload = (event: any) => {

    // }
    reader.readAsDataURL(this.fileToUpload);


  }

  _handleReaderLoaded(readerEvt) {
    const binaryString = readerEvt.target.result;
    this.contentAreas.Base64Data = btoa(binaryString);
  }
  Edit(ContentAreaId) {

    this.isRequired = false;
    this.GetContentAreaById(ContentAreaId);
    const currentContentArea = new ContentAreas();
    this.contentAreas = Object.assign({}, currentContentArea);
    this.ChangeView();
    // this.imgSize = true;
    // this.imgType = true;
    // this.imgAspectRatio=true;
  }
  GetContentAreaById(ContentAreaId) {
    this.contentAreasService.GetContentAreaById(ContentAreaId)
      .subscribe((data: any) => {
        if (data.IsSuccess) {
          this.contentAreas = data.Result;
          if (data.Result.IconImage != null) {
            this.imgURL = data.Result.IconImage;
            this.imgRevert = data.Result.IconImage;
            this.image = data.Result.IconImage.split('/');
            this.image = this.image[this.image.length - 1];
            this.contentAreas.IconImage = this.image;

            this.noImage = false;

          } else {
            this.noImage = true;
          }
          this.clone();
        }
      }, (err: HttpErrorResponse) => {
        console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
      });

  }
  DeletePopUp(ContentAreaId) {
    this.Id = ContentAreaId;
    this.infoModal.show();

  }
  Delete(ContentAreaId) {
    this.infoModal.hide();
    this.contentAreasService.DeleteContentArea(ContentAreaId)
      .subscribe((data: any) => {
        console.log(data);
        if (data.IsSuccess) {
          this.GetContentAreas();
          this.toasterService.pop('success', '', 'Record Deleted Successfully');

        }
        else 
        {
          this.toasterService.pop('error', '', data.Message);
        }
      }, (err: HttpErrorResponse) => {


        console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
      });
  }
  onChangePage(pageOfItems: Array<any>) {
    console.log(pageOfItems);
    // update current page of items
    this.pageOfItems = pageOfItems;
  }
  FilterGrid(searchText) {
    console.log(searchText);
    if (searchText == '') {
      this.items = this.contentAreaList;
    } else {
      searchText = searchText.replace(/[^\w\s]/gi, ' ').toUpperCase().trim();
      this.items = this.contentAreaList.filter(x =>
        x.AreaName.replace(/[^\w\s]/gi, ' ').toUpperCase().search(searchText) >= 0
        //  ||
        // x.FullName.replace(/[^\w\s]/gi, ' ').toUpperCase().search(searchText) >= 0 ||
        // x.WorkEmail.replace(/[^\w\s]/gi, ' ').toUpperCase().search(searchText) >= 0 
        // x.MobileNo.replace(/[^\w\s]/gi, ' ').toUpperCase().search(searchText) >= 0

      );
    }

  }
  Back() {
    this.ChangeView();
    this.imgSize = false;
    this.imgType = false;
    this.imgAspectRatio=false;
    this.noImage = true;
  }
  GetSummary(data) {
    var i: number = 0;
    for (i; i < data.length; i++) {
      this.summaryArraysplit = null;
      if (data[i].Summary != null) {
        if (data[i].Summary.length > this.splitChar) {
          this.summaryArraysplit = data[i].Summary.substr(0, this.splitChar) + '...';
        }
      }
      this.contentAreaList[i].Summarywithsplit = this.summaryArraysplit;
    }
    console.log(this.contentAreaList);
  }

  public view(ContentAreaId) {
    this.router.navigate([`content-topics/` + ContentAreaId + ``]);
  }
  pagesize($event) {
    this.isPageSize = false;
    setTimeout(() => {
      this.pageSize = Number($event.target.value);
      this.isPageSize = true;
    }, 500);
    console.log(this.pageOfItems);
  }

}
