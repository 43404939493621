import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import{Changepassword} from './changepassword';


@Injectable({
  providedIn: 'root'
})
export class ChangepasswordService {

  constructor(private http: HttpClient) { }
  changepassword(changepassword:Changepassword) {
    debugger;
    return this.http.post<any>(`${environment.apiUrl}api/User/ChangePassword`, changepassword);
  }
}
