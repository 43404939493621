import { Component, OnInit } from '@angular/core';
import { AceService  } from './ace.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToasterService, ToasterConfig } from 'angular2-toaster';

@Component({
  selector: 'app-ace-intergation',
  templateUrl: './ace-intergation.component.html',
  styleUrls: ['./ace-intergation.component.css'],
  providers: [ToasterService]
})
export class AceIntergationComponent implements OnInit {
  //message:any;
  private toasterService: ToasterService;
  public toasterconfig: ToasterConfig =
  new ToasterConfig({
  tapToDismiss: true,
  timeout: 3000
});
  constructor(private _aceService:AceService,_toasterService:ToasterService) { 
    this.toasterService = _toasterService;
  }

  ngOnInit() {
  }
  
  loadActiveTypes(){
    this._aceService.LoadActiveTypes()
    .subscribe((data: any) => {
      console.log(data);
      if (data.IsSuccess) {
        this.toasterService.pop('success', '', 'Loaded Activity Types');
       
      }
      else{
        this.toasterService.pop('warrning', '', 'Records Not Found.');
       // this.toasterService.pop("Records Not Found.")
      }
    }, (err: HttpErrorResponse) => {
     this.toasterService.pop('error', '', 'Problem with the sevice. Please try later');
     // console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
    });
  }
  Loadcustomers(){
    this._aceService.Loadcustomers()
    .subscribe((data: any) => {
      console.log(data);
      if (data.IsSuccess) {
        this.DeleteCustomer();
        this.toasterService.pop('success', '', 'Loaded Customers');
       
      }
      else{
        this.toasterService.pop('warrning', '', 'Records Not Found.');
       // this.toasterService.pop("Records Not Found.")
      }
    }, (err: HttpErrorResponse) => {
     this.toasterService.pop('error', '', 'Problem with the sevice. Please try later');
     // console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
    });
  }
  LoadServiceTypes(){
    this._aceService.LoadServiceTypes()
    .subscribe((data: any) => {
      console.log(data);
      if (data.IsSuccess) {
        this.toasterService.pop('success', '', 'Loaded Service Types');
       
      }
      else{
        this.toasterService.pop('warrning', '', 'Records Not Found.');
       // this.toasterService.pop("Records Not Found.")
      }
    }, (err: HttpErrorResponse) => {
     this.toasterService.pop('error', '', 'Problem with the sevice. Please try later');
     // console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
    });
  }
  LoadProfessionals(){
    this._aceService.LoadProfessionals()
    .subscribe((data: any) => {
      console.log(data);
      if (data.IsSuccess) {
        this.toasterService.pop('success', '', 'Loaded Professionals');
       
      }
      else{
        this.toasterService.pop('warrning', '', 'Records Not Found.');
       // this.toasterService.pop("Records Not Found.")
      }
    }, (err: HttpErrorResponse) => {
     this.toasterService.pop('error', '', 'Problem with the sevice. Please try later');
     // console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
    });
  }
  DeleteCustomer()
  {
    
    this._aceService.DeleteCustomer()
      .subscribe((data: any) => {
      
      
      }, (err: HttpErrorResponse) => {
        console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
        
      });
  }
}
