export class Faq {
    FaqId:number;
    FaqQuestion:string; 
    FaqQtnwithsplit:string;
    FaqAnswer:string;
    FaqAnswithsplit:string;
    IsActive:boolean;
    CreatedOn:Date;
    CreatedBy:number;
    UpdatedOn:Date;
    UpdatedBy:number;
}
