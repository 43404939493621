import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';

import { HomeComponent } from './home/home.component';
import { AppRoutingModule } from './app-routing.module';
import { HeaderComponent } from './layouts/header/header.component';
import { FooterComponent } from './layouts/footer/footer.component';
import { SidebarComponent } from './layouts/sidebar/sidebar.component';
import { LoginComponent } from './login/login.component';
import { LayoutsComponent } from './layouts/layouts.component';
import { UserManagementComponent } from './admin/user-management/user-management.component';
import { ToasterModule} from 'angular2-toaster/angular2-toaster';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ClientsComponent } from './admin/clients/clients.component';
import { SystemUsersComponent } from './admin/system-users/system-users.component';
import { ContentAreasComponent } from './admin/content-areas/content-areas.component';
import { ContentTopicsComponent } from './admin/content-topics/content-topics.component';
import { ContentModulesComponent } from './admin/content-modules/content-modules.component';
import { UsersComponent } from './admin/users/users.component';
import { JwPaginationComponent } from 'jw-angular-pagination';
// Modal Component
import { ModalModule } from 'ngx-bootstrap/modal';
import { CKEditorModule } from 'ng2-ckeditor';
import { ReactiveFormsModule } from '@angular/forms';
// Tooltip Component
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FaqComponent } from './admin/faq/faq.component';
import { ContentModuleVedioComponent } from './admin/content-module-vedio/content-module-vedio.component';
import { CmsPageComponent } from './admin/cms-page/cms-page.component';
import { QuestionComponent } from './admin/question/question.component'; 
import {DragDropModule} from '@angular/cdk/drag-drop';
import { ManageCounsellersComponent } from './admin/manage-counsellers/manage-counsellers.component';
// Datepicker
import { BsDatepickerModule } from 'ngx-bootstrap';
import { ForgetpasswordComponent } from './admin/forgetpassword/forgetpassword.component';
import { ChangepasswordComponent } from './admin/changepassword/changepassword.component';
import {AutocompleteLibModule} from 'angular-ng-autocomplete';
import { AceIntergationComponent } from './ace-intergation/ace-intergation.component';
import { TestResultComponent } from './admin/test-result/test-result.component';
import { TestResultDeatilsComponent } from './admin/test-result/test-result-deatils/test-result-deatils.component';
import { NgxUiLoaderModule } from  'ngx-ui-loader';
import { ActivationComponent } from './admin/activation/activation.component';
import { ChartsModule } from 'ng2-charts';
import { AdminActivationComponent } from './admin/admin-activation/admin-activation.component';
import { MobileChangepasswordComponent } from './admin/mobile-changepassword/mobile-changepassword.component';
import { Ng2ImgMaxModule } from 'ng2-img-max';
import { TopicContentComponent } from './admin/Topic-ContentManagement/topic-content/topic-content.component';
import { ModuleContentComponent } from './admin/Topic-ContentManagement/module-content/module-content.component';
import { ModulesComponent } from './admin/Module-ContentManagement/modules/modules.component';
import{EmailTemplateComponent}from './admin/email-template/email-template.component';
import { AuthInterceptorService } from './authInterceptor.service';
import { ChatTemplatesComponent } from './admin/chat-templates/chat-templates.component';
import { ServerMaintenanceComponent } from './admin/server-maintenance/server-maintenance.component';

//for multiselect dropdown
import{AngularMultiSelectModule} from 'angular2-multiselect-dropdown';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    LoginComponent,
    LayoutsComponent,
    UserManagementComponent,
    ClientsComponent,
    SystemUsersComponent,
    ContentAreasComponent,
    ContentTopicsComponent,
    ContentModulesComponent,
    UsersComponent,    
    JwPaginationComponent,
    FaqComponent,
    ContentModuleVedioComponent,
    CmsPageComponent,
    QuestionComponent,
    ManageCounsellersComponent,
    ForgetpasswordComponent,
    ChangepasswordComponent,
    AceIntergationComponent,
    TestResultComponent,
    TestResultDeatilsComponent,
    ActivationComponent,
    AdminActivationComponent,
    MobileChangepasswordComponent,
    TopicContentComponent,
    ModuleContentComponent,
    ModulesComponent,
    EmailTemplateComponent,
    ChatTemplatesComponent,
    ServerMaintenanceComponent
    
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,  
    AppRoutingModule,
    ToasterModule,
    CKEditorModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    TooltipModule.forRoot(),
    BsDatepickerModule.forRoot(),
    DragDropModule,
    AutocompleteLibModule,
    NgxUiLoaderModule,
    ChartsModule,
    Ng2ImgMaxModule,
    AngularMultiSelectModule
  ],
  providers: [ {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptorService,
    multi: true,
  },],
  bootstrap: [AppComponent]
})
export class AppModule { }
