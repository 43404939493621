import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import{ContentModules}from './content-modules';
@Injectable({
  providedIn: 'root'
})
export class ContentModulesService {

  constructor(private http: HttpClient) { }

  GetContentModule(Id:number,Id1:number) {
    return this.http.get<any>(`${environment.apiUrl}api/ContentModule/GetContentModule?ContentAreaId=` + Id+'&ContentTopicId='+Id1);
  }
  GetContentModuleById(Id: number,Id1:number,Id2:number) {
    return this.http.get<any>(`${environment.apiUrl}api/ContentModule/GetContentModuleById?ContentModuleId=` + Id+'&ContentAreaId=' + Id1+'&ContentTopicId='+Id2);
  }
  GetContentAreas() {
    return this.http.get<any>(`${environment.apiUrl}api/ContentArea/GetContentAreas`);
  }
  
  GetContentTopics(Id:number) {
    return this.http.get<any>(`${environment.apiUrl}api/ContentTopic/GetContentTopicByAreaId?ContentAreaId=`+Id);
  }
  AddUpdateContentModule(contentModules: ContentModules) {
    return this.http.post<any>(`${environment.apiUrl}api/ContentModule/AddUpdateContentModule`, contentModules);
  }
  DeleteContentModule(Id:number)
  {
    //return this.http.post<number>(`${environment.apiUrl}api/ContentArea/DeleteContentArea?ContentAreaId=` + Id);
    return this.http.get<any>(`${environment.apiUrl}api/ContentModule/DeleteContentModule?ContentModuleId=` + Id);
  }
  UpdateorderBy(object)
  {
    return this.http.post<any>(`${environment.apiUrl}api/ContentModule/UpdateorderBy`, object);
  }
  GetContentModules() {
    return this.http.get<any>(`${environment.apiUrl}api/ContentModule/GetContentModules`);
  }
}
