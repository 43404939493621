import { DecimalPipe } from "@angular/common";

export class DashBoardWellBeing {
    TotalSubmitted:DecimalPipe;
    TotalCompleted:DecimalPipe;
    TotalDropped:DecimalPipe;
    AvgScore:DecimalPipe; 
    LastMonthTotalSubmitted:DecimalPipe;
    LastMonthTotalCompleted:DecimalPipe;
    LastMonthTotalDropped:DecimalPipe; 
    LastMonthAvgScore:DecimalPipe;
}
