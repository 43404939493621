import { Component, OnInit, ViewChild } from '@angular/core';
import { ToasterService, ToasterConfig } from 'angular2-toaster';
import {FaqService}from './faq.service';
import {Faq}from './faq';
import { HttpErrorResponse } from '@angular/common/http';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader'; // Import NgxUiLoaderService
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css'],
  providers: [ToasterService]
})
export class FaqComponent implements OnInit {
  @ViewChild('infoModal',) public infoModal: ModalDirective;
  splitChar: number = 100;
  arraysplit: string;
  isListView = true;
  faq: Faq;
  faqRevert: Faq = new Faq();
  private toasterService: ToasterService;
  private router: Router;
  private faqService: FaqService;
  faqList: Array<Faq>;
  items: Array<any>;
  pageOfItems: Array<any>;
  pageSize = 10;
  isPageSize = true;
  Id: number;
  ckeConfig: any;
  public toasterconfig: ToasterConfig =
  new ToasterConfig({
    tapToDismiss: true,
    timeout: 3000
  });
constructor(toasterService: ToasterService,_router:Router,_faqService: FaqService,private ngxService: NgxUiLoaderService) {
  this.toasterService = toasterService;
  this.faqService = _faqService;
  this.router=_router;
}

ngOnInit() {
  this.GetFAQs();
  this.ckeConfig = {
    forcePasteAsPlainText: true
  };
}
ChangeView() {
  this.isListView = this.isListView ? false : true;

}
AddNew() {
  const currentFaq = new Faq();
  this.faq = Object.assign({}, currentFaq);
  this.faqRevert = Object.assign({}, currentFaq);
  this.ChangeView();

}
showSuccess() {
  this.toasterService.pop('success', '', 'Data saved Successfully !');
}
showWarning() {
  this.toasterService.pop('warning', '', 'Data Reset Successfully');
}
Save() {
  this.faqService.AddUpdatefaq(this.faq)
    .subscribe((data: any) => {
      console.log(data);
      if (data.IsSuccess) {
       
          this.toasterService.pop('success', '', 'FAQ Added Successfully');
          this.ChangeView();
          this.GetFAQs();
       
      }
    }, (err: HttpErrorResponse) => {
      console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
    });
}
GetFAQs()
{
  this.ngxService.start();
  this.faqService.GetFAQs()
  .subscribe((data: any) => {
    console.log(data);
    if (data.IsSuccess) {
      this.faqList = data.Result;
      this.GetFaqAns(this.faqList);
      this.items = this.faqList;
    }
    this.ngxService.stop();
  }, (err: HttpErrorResponse) => {
    console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
    this.ngxService.stop();
  });
}
onChangePage(pageOfItems: Array<any>) {
  console.log(pageOfItems);
  // update current page of items
  this.pageOfItems = pageOfItems;
}
FilterGrid(searchText) {
  console.log(searchText);
  if (searchText == '') {
    this.items = this.faqList;
  } else {
    searchText = searchText.replace(/[^\w\s]/gi, ' ').toUpperCase().trim();
    this.items = this.faqList.filter(x =>
      x.FaqQuestion.replace(/[^\w\s]/gi, ' ').toUpperCase().search(searchText) >= 0
      //  ||
      // x.FullName.replace(/[^\w\s]/gi, ' ').toUpperCase().search(searchText) >= 0 ||
      // x.WorkEmail.replace(/[^\w\s]/gi, ' ').toUpperCase().search(searchText) >= 0 
      // x.MobileNo.replace(/[^\w\s]/gi, ' ').toUpperCase().search(searchText) >= 0

    );
  }

}
clone() {
  this.faqRevert = Object.assign({}, this.faq);
}
Reset() {
  const itemObj = Object.assign({}, this.faqRevert);
  this.faq = itemObj;
}
Edit(FaqId) {
  this.GetFAQById(FaqId);
  const currentfaq = new Faq();
  this.faq = Object.assign({}, currentfaq);
  this.ChangeView();
}
GetFAQById(FaqId) {
  this.faqService.GetFAQById(FaqId)
    .subscribe((data: any) => {
      if (data.IsSuccess) {
        this.faq = data.Result; 
        this.clone();
      }
    }, (err: HttpErrorResponse) => {
      console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
    });

}
Back() {
  this.ChangeView();
}
DeletePopUp(FaqId) {
  this.Id = FaqId;
  this.infoModal.show();

}
Delete(FaqId) {
  this.infoModal.hide();
  this.faqService.DeleteFaq(FaqId)
    .subscribe((data: any) => {
      console.log(data);
      if (data.IsSuccess) {
        this.GetFAQs();
        this.toasterService.pop('success', '', 'Record Deleted Successfully');

      }
    }, (err: HttpErrorResponse) => {
      
      
      console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
    });
}
GetFaqAns(data) {
  var i: number = 0;
  for (i; i < data.length; i++) {
    this.arraysplit = null;
    if (data[i].FaqQuestion != null) {
      if (data[i].FaqQuestion.length > this.splitChar) {
        this.arraysplit = data[i].FaqQuestion.substr(0, this.splitChar) + '...';
      }
    }
    this.faqList[i].FaqQtnwithsplit = this.arraysplit;
  }
  var i: number = 0;
  for (i; i < data.length; i++) {
    this.arraysplit = null;
    if (data[i].FaqAnswer != null) {
      if (data[i].FaqAnswer.length > this.splitChar) {
        this.arraysplit = data[i].FaqAnswer.substr(0, this.splitChar) + '...';
      }
    }
    this.faqList[i].FaqAnswithsplit = this.arraysplit;
  }
}
pagesize($event) {
  this.isPageSize = false;
  setTimeout(() => {     
    this.pageSize = Number($event.target.value);
    this.isPageSize = true;
  }, 500);
 console.log( this.pageOfItems);
}
}
