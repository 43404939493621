import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToasterService, ToasterConfig } from 'angular2-toaster';
import { LoginService } from 'src/app/login/login.service';
import { User } from 'src/app/admin/users/User';
import { NgxUiLoaderService } from 'ngx-ui-loader'; // Import NgxUiLoaderService
@Component({
  selector: 'app-forgetpassword',
  templateUrl: './forgetpassword.component.html',
  styleUrls: ['./forgetpassword.component.css'],
  providers: [ToasterService]
})
export class ForgetpasswordComponent implements OnInit {
  user = new User();
  showMsg=false;
  showForm=false;
  isListView=false;
  Password;
  emailsubmitted=false;
  forgetemail:FormGroup;
  UserId: string;
  GUID:string;
  ConfirmPassword;
  private router: Router;
  private route: ActivatedRoute;
  private loginService: LoginService;
  private toasterService: ToasterService;
  public toasterconfig: ToasterConfig =
    new ToasterConfig({
      tapToDismiss: true,
      timeout: 3000
    });
  constructor(private formBuilder: FormBuilder, _loginService: LoginService, toasterService: ToasterService, _route: ActivatedRoute, _router: Router,private ngxService: NgxUiLoaderService) {
    this.toasterService = toasterService;
    this.loginService = _loginService;
    this.route = _route;
    this.router = _router;
    console.log(this.route.url);
    this.UserId = this.route.snapshot.children[0].url[1].path;
    this.GUID=this.route.snapshot.children[0].url[2].path;

  }
  ngOnInit() {
    this.forgetemail = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      //passWord: ['', Validators.required],
  });
    this.CheckUrlisExpiredOrNot();
  }
  onSubmit() {
    if (this.Password == this.ConfirmPassword) {
      var forgotpassword = {};
      forgotpassword['UserId'] = this.UserId;
      forgotpassword['Password'] = this.Password;
      this.loginService.updatePassword(forgotpassword)
        .subscribe((data: any) => {
          console.log(data);
          if (data.IsSuccess) {

            this.toasterService.pop('success', '', 'Password Changed Successfully');
            this.router.navigate([`/login`]);
          }
        }, (err: HttpErrorResponse) => {
          console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
        });

    }
    else {
      this.toasterService.pop('error', '', 'Passwords do not match');
    }
  }
  CheckUrlisExpiredOrNot()
    {
      this.ngxService.start();
    this.loginService.CheckUrlisExpiredOrNot(this.UserId,this.GUID)
    .subscribe((data: any) => {
      console.log(data);
      if (data.IsSuccess) {
       if(data.Message=='Expired')
       {
         this.showMsg=true;
         this.showForm=false;
         this.isListView=false;
       }
       else if(data.Message=='Unexpired')
       {
        this.showMsg=false;
        this.showForm=true;
        this.isListView=false;
       }
    
      }
      this.ngxService.stop();
    }, (err: HttpErrorResponse) => {
      console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
      this.ngxService.stop();
    });
  }
  send()
{
let object={
   'Email':this.forgetemail.get('email').value,
}
this.emailsubmitted = true;
    // stop here if form is invalid
    if (this.forgetemail.invalid) {
        return;
    }
  
  else{ 
    this.ngxService.start();
    this.loginService.ForgetPassword(object)
  .subscribe((data: any) => {
    console.log(data);
    if (data.IsSuccess) {
      if(data.Result=='Success')
      {
      this.toasterService.pop('success', '', 'Email Sent Successfully');
      //this.ChangeView();
      this.router.navigate([`/login`]);
      }
      
      if(data.Result=='Invalid User')
      {
        this.toasterService.pop('error', '', 'Invalid User.');
      }
    }
    this.ngxService.stop();
  }, (err: HttpErrorResponse) => {   
    console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
    this.ngxService.stop();
  });
}
}
get fe() { return this.forgetemail.controls; }
forgetpassword()
{
  this.user.Email='';
  this.isListView=true;
  this.showMsg=false;
  this.showForm=false;
}
Cancel()
{
  this.CheckUrlisExpiredOrNot();
}
}
