// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
//  apiUrl: 'https://localhost:44305/', // Dev End point
  //apiUserUrl: 'https://eapaccessportal.azurewebsites.net/',//User End Point // default added in latest code from team
  apiUserUrl: 'https://eapaccessportalstaging.azurewebsites.net/',//User End Point // Added by me ms
//  apiUrl: 'https://portal.accesseap.com.au/', // Live End point
   //apiUrl: 'https://localhost:44305/',
   apiUrl: 'https://eapappstaging.azurewebsites.net/', // Staging End point
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
