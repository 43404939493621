import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  showManagementTab:boolean=false;
  constructor(private router: Router) { }

  ngOnInit() {
    this.loadScript();
  }
  public loadScript() {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = '../assets/coreui/main.js';
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }
  logOut()//[routerLink]="['/login']"
  {
    localStorage.clear();
    this.router.navigate(['/login']);

  }
  showHideManagementTab()
  {
    this.showManagementTab = !this.showManagementTab;
  }
}
