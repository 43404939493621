import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { User } from 'src/app/admin/users/User';
import { Router } from '@angular/router';



@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  user = new User();
  currentUserSubscription: Subscription;
  constructor(private router: Router) {

  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('UserDetails'));
  }
  logOut()//[routerLink]="['/login']"
  {
    localStorage.clear();
    this.router.navigate(['/login']);

  }
  sidemenutoggle() {
    debugger;
    var element = document.getElementById('linksidebar');
    if (element) {
      element.click();
    }

  }

}
