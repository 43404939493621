import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import{ContentTopics}from './content-topics';

@Injectable({
  providedIn: 'root'
})
export class ContentTopicsService {

  constructor(private http: HttpClient) { }
  GetContentTopics(Id: number) {
    return this.http.get<any>(`${environment.apiUrl}api/ContentTopic/GetContentTopics?ContentAreaId=`+Id);
  }
  GetContentTopicById(Id: number,Id1:number) {
    return this.http.get<any>(`${environment.apiUrl}api/ContentTopic/GetContentTopicById?ContentTopicId=` + Id+'&ContentAreaId='+Id1);
  }
  GetContentAreas() {
    return this.http.get<any>(`${environment.apiUrl}api/ContentArea/GetContentAreas`);
  }
  
  AddUpdateContentTopic(contentTopics: ContentTopics) {
    return this.http.post<any>(`${environment.apiUrl}api/ContentTopic/AddUpdateContentTopic`, contentTopics);
  }
  DeleteContentTopic(Id:number)
    {
      //return this.http.post<number>(`${environment.apiUrl}api/ContentArea/DeleteContentArea?ContentAreaId=` + Id);
      return this.http.get<any>(`${environment.apiUrl}api/ContentTopic/DeleteContentTopic?ContentTopicId=` + Id);
    }
    GetAllContentTopics() {
      return this.http.get<any>(`${environment.apiUrl}api/ContentTopic/GetAllContentTopics`);
    }
}
