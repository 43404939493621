import { Component, OnInit } from '@angular/core';
import { ToasterService, ToasterConfig } from 'angular2-toaster';
import { Router, ActivatedRoute } from '@angular/router';
import { TestResultService } from '../test-result.service';
import { HttpErrorResponse } from '@angular/common/http';
import { TestResultDetails } from './test-result-details';

@Component({
  selector: 'app-test-result-deatils',
  templateUrl: './test-result-deatils.component.html',
  styleUrls: ['./test-result-deatils.component.css'],
  providers: [ToasterService]
})
export class TestResultDeatilsComponent implements OnInit {
  isListView = true;
  pageOfItems: Array<any>;
  pageSize = 10;
  isPageSize = true;
  testResultList: Array<TestResultDetails>;
  items: Array<any>;
  private toasterService: ToasterService;
  public CheckTestId:number;
  private router: Router;
  private testResultService: TestResultService;
  private route: ActivatedRoute;
  public toasterconfig: ToasterConfig =
    new ToasterConfig({
      tapToDismiss: true,
      timeout: 3000
    });
  constructor(toasterService: ToasterService,_router: Router,_route:ActivatedRoute,_testResultService: TestResultService,) {

    this.toasterService = toasterService;
    this.router = _router;
    this.testResultService = _testResultService;
    this.route=_route;
   // this.CheckTestId =parseInt(this.route.snapshot.paramMap.get("id"));
   //this.route.queryParams.subscribe(params => {
    //this.CheckTestId = params['id'];
   // this.param2 = params['param2'];
   
//});
this.CheckTestId =parseInt(this.route.snapshot.paramMap.get("id"));
  }


  ngOnInit() {
    this.GetTestResultById(this.CheckTestId);
  }
  // public view(ContentAreaId) {
  //   debugger;
  //   this.router.navigate([`content-topics/` + ContentAreaId + ``]);
  // }
  ChangeView() {
    this.isListView = this.isListView ? false : true;

  }
  AddNew() {
    this.ChangeView();
  }
  showSuccess() {
    this.toasterService.pop('success', '', 'Data saved Successfully !');
  }
  showWarning() {
    this.toasterService.pop('warning', '', 'Data Reset Successfully');
  }
  Save() {
    this.showSuccess();
    setTimeout(() => {
      this.ChangeView();
    }, 3000);   
  }
  Reset() {
    this.showWarning();
    setTimeout(() => {
      //this.ChangeView();
    }, 3000);   
  }
  GetTestResultById(CheckTestId)
  {
    this.testResultService.GetTestResultById(CheckTestId)
      .subscribe((data: any) => {
        console.log(data);
        if (data.IsSuccess) {
          this.testResultList = data.Result;
         // this.GetSummary(this.contentTopicList);
          this.items = this.testResultList;          
        }
      }, (err: HttpErrorResponse) => {
        console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
      });
  }
  FilterGrid(searchText) {
    console.log(searchText);
    if (searchText == '') {
      this.items = this.testResultList;
    } else {
      searchText = searchText.replace(/[^\w\s]/gi, ' ').toUpperCase().trim();
      this.items = this.testResultList.filter(x =>
        x.QuestionText.replace(/[^\w\s]/gi, ' ').toUpperCase().search(searchText) >= 0
      ||x.AnswerText.replace(/[^\w\s]/gi, ' ').toUpperCase().search(searchText) >= 0 
 //||
        // x.WorkEmail.replace(/[^\w\s]/gi, ' ').toUpperCase().search(searchText) >= 0 
        // x.MobileNo.replace(/[^\w\s]/gi, ' ').toUpperCase().search(searchText) >= 0
  
      );
    }
  
  }
  onChangePage(pageOfItems: Array<any>) {
    console.log(pageOfItems);
    // update current page of items
    this.pageOfItems = pageOfItems;
  }
}

