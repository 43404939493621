import { Component, OnInit ,ViewChild} from '@angular/core';
import { ToasterService, ToasterConfig } from 'angular2-toaster';
import { ContentTopicsService } from '../../content-topics/content-topics.service';
import{ContentTopics}from '../../content-topics/content-topics';
import { HttpErrorResponse } from '@angular/common/http';
import { ContentAreas } from '../../content-areas/content-areas';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader'; // Import NgxUiLoaderService
@Component({
  selector: 'app-topic-content',
  templateUrl: './topic-content.component.html',
  styleUrls: ['./topic-content.component.css'],
  providers: [ToasterService]
})
export class TopicContentComponent implements OnInit {
  @ViewChild('infoModal') public infoModal: ModalDirective;
  aspectRatio:number = 1.78;
  fixImgSize: number = 800;
  imgSize: boolean = false;
  imgType: boolean = false;
  imgAspectRatio: boolean = false;
  isRequired=true;
  splitChar: number = 100;
  summaryArraysplit: string;
  imgRevert:string='';
  Id: number;
  noImage: boolean = true;
  picture: any;
  fileToUpload: File;
  files: any;
  imgURL: string = '';
  image: string;
  items: Array<any>;
  pageOfItems: Array<any>;
  pageSize = 10;
  isPageSize = true;
  contentAreaLst:ContentAreas[];
  contentAreaList: Array<ContentAreas>;
  isListView = true;
  contentTopicList: Array<ContentTopics>;
  contentTopicRevert: ContentTopics = new ContentTopics();
  contentTopic=new  ContentTopics();
  private toasterService: ToasterService;
  private contentTopicsService: ContentTopicsService;
  private router: Router;
  private route: ActivatedRoute;
  public ContentAreaId:number;
  public AreaName:string;
  public message:string="";
  public toasterconfig: ToasterConfig =
    new ToasterConfig({
      tapToDismiss: true,
      timeout: 3000
    });
  constructor(toasterService: ToasterService,_contentTopicsService: ContentTopicsService,_router:Router,_route:ActivatedRoute,private ngxService: NgxUiLoaderService) {
    this.toasterService = toasterService;
    this.contentTopicsService = _contentTopicsService;
    this.router=_router;
    this.route=_route;
   // this.ContentAreaId =parseInt(this.route.snapshot.paramMap.get("id"));
  }

  ngOnInit() {

    this.GetContentTopics();
    this.GetContentAreas();
  }
  ChangeView() {
    this.isListView = this.isListView ? false : true;

  }
  AddNew() {
    this.isRequired=true;
    this.GetContentAreas();
    //this.imgURL="";
    this.noImage = true;
    this.contentTopic = new ContentTopics();
    this.contentTopic.ContentAreaId=undefined;
    this.clone();
    this.ChangeView();

  }
  showSuccess() {
    this.toasterService.pop('success', '', 'Data saved Successfully !');
  }
  showWarning() {
    this.toasterService.pop('warning', '', 'Data Reset Successfully');
  }
  Save() {
    if(this.imgType || this.imgSize ||  this.imgAspectRatio){
      return false;
    }
    if (this.imgURL != '')
    this.contentTopic.Base64Data = this.imgURL.split(',')[1];
  this.contentTopicsService.AddUpdateContentTopic(this.contentTopic)
    .subscribe((data: any) => {
      console.log(data);
      if (data.IsSuccess) {
          this.toasterService.pop('success', '', 'ContentTopic Added Successfully');
          this.ChangeView();
          this.GetContentTopics();

      }
    }, (err: HttpErrorResponse) => {
      console.log(err.error.Message);
      this.message=err.error.Message;
      if(this.message.includes("Cannot insert duplicate key row in object 'dbo.ContentTopics' with unique index 'IX_ContentTopics'."))
      this.toasterService.pop('error', '', 'TopicName Is Already Exist');
      console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
    });  
  }
  Reset() {

    const itemObj = Object.assign({}, this.contentTopicRevert);
    this.contentTopic = itemObj;
    this.GetContentAreas();
    this.contentTopic.ContentAreaId=this.ContentAreaId;
    if(this.contentTopic.IconImage==null)
      this.noImage = true;  
    // if(this.contentTopic.ContentAreaId==undefined)
    //   this.contentTopic.ContentAreaId=0;
    if(this.contentTopic.IconImage!=null)
      this.imgURL=this.imgRevert;
      this.imgSize = false;
      this.imgType = false;
      this.imgAspectRatio=false;
     

  }
  GetContentTopics()
  {
    this.ngxService.start();
    this.contentTopicsService.GetAllContentTopics()
      .subscribe((data: any) => {
        console.log(data);
        if (data.IsSuccess) {
          this.contentTopicList = data.Result;
          this.GetSummary(this.contentTopicList);
          this.items = this.contentTopicList;          
          
        }
        this.ngxService.stop();
      }, (err: HttpErrorResponse) => {
        console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
        this.ngxService.stop();
      });
  }
  GetContentAreas() {
    this.contentTopicsService.GetContentAreas()
      .subscribe((data: any) => {
        console.log(data);
        if (data.IsSuccess) {
          this.contentAreaList = data.Result;
          let index = this.contentAreaList.findIndex(c => c.ContentAreaId == this.ContentAreaId);
          let area = this.contentAreaList[index];
          this.AreaName=area.AreaName;
          console.log(area.AreaName);
        }
      }, (err: HttpErrorResponse) => {
        console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
      });
  }
  FilterGrid(searchText) {
    console.log(searchText);
    if (searchText == '') {
      this.items = this.contentTopicList;
    } else {
      searchText = searchText.replace(/[^\w\s]/gi, ' ').toUpperCase().trim();
      this.items = this.contentTopicList.filter(x =>
        ((x.TopicName?true:false) &&  (x.TopicName.replace(/[^\w\s]/gi, ' ').toUpperCase().search(searchText) >= 0))||
        ((x.ContentAreaName?true:false) &&  (x.ContentAreaName.replace(/[^\w\s]/gi, ' ').toUpperCase().search(searchText) >= 0))
        //  ||
        // x.FullName.replace(/[^\w\s]/gi, ' ').toUpperCase().search(searchText) >= 0 ||
        // x.WorkEmail.replace(/[^\w\s]/gi, ' ').toUpperCase().search(searchText) >= 0 
        // x.MobileNo.replace(/[^\w\s]/gi, ' ').toUpperCase().search(searchText) >= 0

      );
    }

  }
  onChangePage(pageOfItems: Array<any>) {
    console.log(pageOfItems);
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

  selectPhoto(file: FileList) {
    this.picture = file.item(0).name;
    this.fileToUpload = file.item(0);
    const File = this.fileToUpload.name;
    this.contentTopic.FileName = File.split('.')[0];
    this.files = File.split('.');
    this.contentTopic.FileType = this.files[this.files.length - 1];
    var regex = new RegExp("(jpg|png|gif|jpeg)$");
    if (regex.test(this.contentTopic.FileType.toLowerCase())) {
      this.imgType = false;
      console.log('filetype is correct');
    }
    else {
      this.imgType = true;
    }
    this.noImage = false;
    var totalsize = file.item(0).size;
    var totalSizeKB = totalsize / Math.pow(1024, 1);
    if (totalSizeKB < this.fixImgSize) {
      this.imgSize = false;
    }
    else {
      this.imgSize = true;
    }
    // Show Image Preview
    const reader = new FileReader();
    reader.onload = (event: any) => {
      this.imgURL = event.target.result;
      var image = new Image();
      image.src = event.target.result;
      image.onload = () => {
        var inputHeight = image.height;
        var inputWidth = image.width;
        var ratio = inputWidth / inputHeight;
        var n = parseFloat(ratio.toFixed(2));
        let outputWidth = inputWidth;
        let outputHeight = inputHeight;
        // if (n > this.aspectRatio) {
        //     outputWidth = inputHeight * this.aspectRatio;
        // } else if (n < this.aspectRatio) {
            outputHeight = inputWidth / this.aspectRatio;
       // }
       let marginHeightMax=outputHeight+(outputHeight*(10/100));
       let marginHeightMin=outputHeight-(outputHeight*(10/100));

       
        if (marginHeightMin<inputHeight && marginHeightMax>inputHeight) {
          this.imgAspectRatio = false;
        }
        else
        this.imgAspectRatio = true;
      };
    };
    reader.readAsDataURL(this.fileToUpload);
  
  }

  _handleReaderLoaded(readerEvt) {
    const binaryString = readerEvt.target.result;
    this.contentTopic.Base64Data = btoa(binaryString);
  }
  Edit(ContentTopicId,ContentAreaId) {
    this.isRequired=false;
    this.GetContentAreas();
    this.GetContentTopicById(ContentTopicId,ContentAreaId);
    this.ChangeView();
  }
  clone() {
    this.contentTopicRevert = Object.assign({}, this.contentTopic);
    console.log(this.contentTopic);
  }
  GetContentTopicById(ContentTopicId,ContentAreaId) {
    this.contentTopicsService.GetContentTopicById(ContentTopicId,ContentAreaId)
      .subscribe((data: any) => {
        if (data.IsSuccess) {
          this.contentTopic = data.Result;
          if (data.Result.IconImage != null) {
            this.imgURL = data.Result.IconImage;
            this.imgRevert=data.Result.IconImage;
            this.image = data.Result.IconImage.split('/');
            this.image = this.image[this.image.length - 1];
            this.contentTopic.IconImage = this.image;

            this.noImage = false;

          } else {
            this.noImage = true;
          }
          this.clone();
        }
      }, (err: HttpErrorResponse) => {
        console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
      });

  }
  Back() {
    this.ChangeView();
    this.imgSize = false;
    this.imgType = false;
    this.imgAspectRatio=false;
    this.noImage = true;
  }
  DeletePopUp(ContentAreaId) {
    this.Id = ContentAreaId;
    this.infoModal.show();

  }
  Delete(ContentAreaId) {
    this.infoModal.hide();
    this.contentTopicsService.DeleteContentTopic(ContentAreaId)
      .subscribe((data: any) => {
        console.log(data);
        if (data.IsSuccess) {
          this.GetContentTopics();
          this.toasterService.pop('success', '', 'Record Deleted Successfully');

        }
        else 
        {
          this.toasterService.pop('error', '', data.Message);
        }
      }, (err: HttpErrorResponse) => {
        console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
      });
  }
  GetSummary(data) {
    var i: number = 0;
    for (i; i < data.length; i++) {
      this.summaryArraysplit = null;
      if (data[i].Summary != null) {
        if (data[i].Summary.length > this.splitChar) {
          this.summaryArraysplit = data[i].Summary.substr(0, this.splitChar) + '...';
        }
      }
      this.contentTopicList[i].Summarywithsplit = this.summaryArraysplit;
    }
    console.log(this.contentTopicList);
  }
  public view(ContentAreaId,ContentTopicId) {
    this.router.navigate([`topic-module/` + ContentAreaId+ '/' + ContentTopicId + ``]);
  }
  pagesize($event) {
    this.isPageSize = false;
    setTimeout(() => {     
      this.pageSize = Number($event.target.value);
      this.isPageSize = true;
    }, 500);
   console.log( this.pageOfItems);
  }
}