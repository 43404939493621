import { Component, OnInit, ViewChild } from '@angular/core';
import { ToasterService, ToasterConfig } from 'angular2-toaster';
import { HttpErrorResponse } from '@angular/common/http';
import { ChatTemplatesService } from './ChatTemplates.service';
import {ChatTemplate} from './ChatTemplate'
import { from } from 'rxjs';
import {ModalDirective} from 'ngx-bootstrap/modal';
import { NgxUiLoaderService } from 'ngx-ui-loader'; // Import NgxUiLoaderService

@Component({
  selector: 'app-chat-templates',
  templateUrl: './chat-templates.component.html',
  styleUrls: ['./chat-templates.component.css'],
  providers: [ToasterService]
})
export class ChatTemplatesComponent implements OnInit {
  @ViewChild('infoModal') public infoModal: ModalDirective;
  isListView = true;
  private toasterService: ToasterService;
  private chattemplatesService: ChatTemplatesService;
  templatesList: Array<ChatTemplate>;
  templates: ChatTemplate = new ChatTemplate();
  templatesRevert: ChatTemplate = new ChatTemplate();
  public message: string = "";
  public toasterconfig: ToasterConfig =
    new ToasterConfig({
      tapToDismiss: true,
      timeout: 3000
    });
    items: Array<any>;
    pageOfItems: Array<any>;
    pageSize=10;
    isPageSize = true;
  constructor(_toasterService: ToasterService,_chattemplatesService: ChatTemplatesService,private ngxService: NgxUiLoaderService) {
    this.toasterService = _toasterService; 
    this.chattemplatesService=_chattemplatesService;
  }

  ngOnInit() {
    this.GetMessageTemplates();
    this.loadScript();
  }
  GetMessageTemplates(){
    this.ngxService.start();
    this.chattemplatesService.GetChatMessages()
      .subscribe((data: any) => {
        console.log(data);
        if (data.IsSuccess) {
          this.templatesList = data.Result;
          this.items = this.templatesList;
        }
        this.ngxService.stop();
      }, (err: HttpErrorResponse) => {
        console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
        this.ngxService.stop();
      });
  }
  public loadScript() {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = '../assets/coreui/main.js';
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }

  AddNew(){
    const Currenttemplate = new ChatTemplate();
    this.templates = Object.assign({}, new ChatTemplate());
    this.templatesRevert= Object.assign({}, Currenttemplate);
    console.log(this.templates);
    this.ChangeView();
    console.log(Currenttemplate);
  }
  ChangeView() {
    this.isListView = this.isListView ? false : true;
  }
  Save() {
    this.ngxService.start();
    let id=JSON.parse(localStorage.getItem('UserDetails'));
    let confirmObj = {
      MessageTemplate: this.templates.MessageTemplate,
      ChatTemplatesID_PK: this.templates.ChatTemplatesID_PK,
      CreatedUserID: id.UserId,
      UpdatedUserID: id.UserId
    }
    this.chattemplatesService.AddUpdateMessageTemplate(confirmObj)
      .subscribe((data: any) => {
        console.log(data);
        if (data.IsSuccess) {
          this.showSuccess();
          this.GetMessageTemplates();
          setTimeout(() => {
            this.ChangeView();
          }, 3000);

        }
        else{
         
          if(data.Message=="Message Template Already Exists")
          this.toasterService.pop('error', '', 'Message Template Already Exists');
         
        }
        this.ngxService.stop();
      }, (err: HttpErrorResponse) => {
        console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
        this.ngxService.stop();
      });
  }
  showSuccess() {
    this.toasterService.pop('success', '', 'Data saved Successfully !');
  }
  Back() {
    this.ChangeView();
  }
  Reset() {
    const itemObj = Object.assign({}, this.templatesRevert);
    this.templates = itemObj;
  }

  FilterGrid(searchText) {
    console.log(searchText);
    if (searchText == '') {
      this.items = this.templatesList;
    } else {
      searchText = searchText.replace(/[^\w\s]/gi, ' ').toUpperCase().trim();
      this.items = this.templatesList.filter(x =>
        //x.UserName.replace(/[^\w\s]/gi, ' ').toUpperCase().search(searchText) >= 0 ||
        x.MessageTemplate.replace(/[^\w\s]/gi, ' ').toUpperCase().search(searchText) >= 0

      );
    }

  }
  pagesize($event) {
    this.isPageSize = false;
    setTimeout(() => {     
      this.pageSize = Number($event.target.value);
      this.isPageSize = true;
    }, 500);
   console.log( this.pageOfItems);
  }

  onChangePage(pageOfItems: Array<any>) {
    console.log(pageOfItems);
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

  GetTemplate(Id,isDelete) {
    localStorage.setItem('TemplateID',Id);
    const currentTemplate = this.templatesList.find(x => x.ChatTemplatesID_PK === Id);
    this.templates = Object.assign({}, currentTemplate);

    this.clone();
    if(!isDelete)
    {
    this.ChangeView();
  }
}

clone() {
  this.templatesRevert = Object.assign({}, this.templates);
  console.log(this.templates);
}
Delete() {
  let Id=JSON.parse(localStorage.getItem('TemplateID'));
  this.chattemplatesService.DeleteTemplate(Id)
  .subscribe((data: any) => {
    console.log(data);
    if (data.IsSuccess) {
      this.toasterService.pop('success', '', 'Record Deleted Successfully');
      this.GetMessageTemplates();
      this.infoModal.hide();
   
    }
  }, (err: HttpErrorResponse) => {
    console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
  });
}
}
