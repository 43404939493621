import { Component, OnInit, ViewChild } from '@angular/core';
import { ToasterService, ToasterConfig } from 'angular2-toaster';
import { CmsPageService } from './cms-page.service';
import { Router } from '@angular/router';
import { CmsPage } from './cms-page';
import { HttpErrorResponse } from '@angular/common/http';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgxUiLoaderService } from 'ngx-ui-loader'; // Import NgxUiLoaderService

@Component({
  selector: 'app-cms-page',
  templateUrl: './cms-page.component.html',
  styleUrls: ['./cms-page.component.css'],
  providers: [ToasterService]
})
export class CmsPageComponent implements OnInit {
  @ViewChild('infoModal') public infoModal: ModalDirective;
  Id: number;
  cmsPageRevert: CmsPage = new CmsPage();
  CmsPage: CmsPage;
  isListView = true;
  cmsPageList: Array<CmsPage>;
  public message:string="";
  private toasterService: ToasterService;
  private cmsPageService: CmsPageService;
  private router: Router;
  items: Array<any>;
  pageOfItems: Array<any>;
  pageSize = 10;
  isPageSize = true;
  ckeConfig: any;
  public toasterconfig: ToasterConfig =
  new ToasterConfig({
    tapToDismiss: true,
    timeout: 3000
  });
constructor(toasterService: ToasterService,_cmsPageService: CmsPageService,_router:Router,private ngxService: NgxUiLoaderService) {
  this.toasterService = toasterService;
  this.cmsPageService = _cmsPageService;
  this.router=_router;
}

ngOnInit() {
  
  this.GetCMSPages();
  this.ckeConfig = {
    forcePasteAsPlainText: true
  };
}
ChangeView() {
  this.isListView = this.isListView ? false : true;

}
AddNew() {
  const currentCmsPage = new CmsPage();
  this.CmsPage = Object.assign({}, currentCmsPage);
  this.cmsPageRevert= Object.assign({}, currentCmsPage);
  this.ChangeView();

}
showSuccess() {
  this.toasterService.pop('success', '', 'Data saved Successfully !');
}
showWarning() {
  this.toasterService.pop('warning', '', 'Data Reset Successfully');
}

GetCMSPages() {
  this.ngxService.start();
  this.cmsPageService.GetCMSPages()
    .subscribe((data: any) => {
      console.log(data);
      if (data.IsSuccess) {
        this.cmsPageList = data.Result;
        this.items = this.cmsPageList;
      }
      this.ngxService.stop();
    }, (err: HttpErrorResponse) => {
      console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
      this.ngxService.stop();
    });
}
onChangePage(pageOfItems: Array<any>) {
  console.log(pageOfItems);
  // update current page of items
  this.pageOfItems = pageOfItems;
}
Save() {
  this.cmsPageService.AddUpdateCMSPage(this.CmsPage)
    .subscribe((data: any) => {
      console.log(data);
      if (data.IsSuccess) {
       
          this.toasterService.pop('success', '', 'Content Added Successfully');
          this.ChangeView();
          this.GetCMSPages();
       
      }
    }, (err: HttpErrorResponse) => {
      this.message=err.error.Message;
      if(this.message.includes("Cannot insert duplicate key in object 'dbo.CMSPages'"))
      this.toasterService.pop('error', 'Error Toaster', 'PageCode Is Already Exist');
      console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
    });
}
Reset() {
  const itemObj = Object.assign({}, this.cmsPageRevert);
  this.CmsPage = itemObj;
}
clone() {
  this.cmsPageRevert = Object.assign({}, this.CmsPage);
}
Edit(CMSPageId) {
  this.GetCMSpageById(CMSPageId);
  const currentCmsPage = new CmsPage();
  this.CmsPage = Object.assign({}, currentCmsPage);
  this.ChangeView();
}
GetCMSpageById(CMSPageId) {
  this.cmsPageService.GetCMSpageById(CMSPageId)
    .subscribe((data: any) => {
      if (data.IsSuccess) {
        this.CmsPage = data.Result;
        this.clone();
      }
    }, (err: HttpErrorResponse) => {
      console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
    });

}
Back() {
  this.ChangeView();
}
DeletePopUp(CmsPage) {
  this.Id = CmsPage;
  this.infoModal.show();

}
Delete(CMSPageId) {
  this.infoModal.hide();
  this.cmsPageService.DeletecmsPage(CMSPageId)
    .subscribe((data: any) => {
      console.log(data);
      if (data.IsSuccess) {
        this.GetCMSPages();
        this.toasterService.pop('success', '', 'Record Deleted Successfully');

      }
    }, (err: HttpErrorResponse) => {
      
      
      console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
    });
}
FilterGrid(searchText) {
  console.log(searchText);
  if (searchText == '') {
    this.items = this.cmsPageList;
  } else {
    searchText = searchText.replace(/[^\w\s]/gi, ' ').toUpperCase().trim();
    this.items = this.cmsPageList.filter(x =>
      x.CMSPageCode.replace(/[^\w\s]/gi, ' ').toUpperCase().search(searchText) >= 0||
       x.CMSPageName.replace(/[^\w\s]/gi, ' ').toUpperCase().search(searchText) >= 0 

    );
  }

}
pagesize($event) {
  this.isPageSize = false;
  setTimeout(() => {     
    this.pageSize = Number($event.target.value);
    this.isPageSize = true;
  }, 500);
 console.log( this.pageOfItems);
}
}