import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AceService {

  constructor(private http: HttpClient) { }
  LoadActiveTypes() {
    return this.http.get<any>(`${environment.apiUserUrl}api/Ace/GetActivityTypes`);
  }
  Loadcustomers() {
    return this.http.get<any>(`${environment.apiUserUrl}api/Ace/GetCustomers`);
  }
  LoadServiceTypes() {
    return this.http.get<any>(`${environment.apiUserUrl}api/Ace/GetServiceTypes`);
  }
  LoadProfessionals() {
    return this.http.get<any>(`${environment.apiUserUrl}api/Ace/GetProfessionals`);
  }
  DeleteCustomer() {
    return this.http.get<any>(`${environment.apiUserUrl}api/Ace/DeleteCustomers`);
  }
  saveCounsollernotshown(OrganisationId: any, SelectedItemsaddstring: any, OrganisationName: string) {
    var queryString = `OrganisationId=${OrganisationId}&SelectedItemsaddstring=${SelectedItemsaddstring}&OrganisationName=${OrganisationName}`
    return this.http.get<any>(`${environment.apiUrl}api/Ace/saveCounsollernotshown?${queryString}`);
  }
}
