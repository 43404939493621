import { Component, OnInit } from '@angular/core';
import{Changepassword}from './changepassword';
import { ChangepasswordService } from './changepassword.service';
import { ToasterService, ToasterConfig } from 'angular2-toaster';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { User } from '../users/User';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css'],
  providers: [ToasterService]

})
export class ChangepasswordComponent implements OnInit {
  contentTopicRevert: Changepassword = new Changepassword();
changepassword=new Changepassword();
user=new User();
private router: Router;
private changepasswordService: ChangepasswordService;
private toasterService: ToasterService;
confirmpassword:string;
public toasterconfig: ToasterConfig =
new ToasterConfig({
  tapToDismiss: true,
  timeout: 3000
});
  constructor(toasterService: ToasterService,_changepasswordService: ChangepasswordService,_router:Router) { 
    this.toasterService = toasterService;
    this.changepasswordService = _changepasswordService;
    this.router=_router;
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('UserDetails'));
  }

  onSubmit()
  {
    this.changepassword.UserId=this.user.UserId;
    if (this.changepassword.Password == this.confirmpassword) {
      this.changepasswordService.changepassword(this.changepassword)
        .subscribe((data: any) => {
          if (data.IsSuccess) {

            this.toasterService.pop('success', '', 'Password Changed Successfully');
            this.router.navigate([`/dashboard`]);
          }
          else{
            this.toasterService.pop('error', '', 'Old Password Do Not Match With Existing Password');
          }
        }, (err: HttpErrorResponse) => {
          console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
        });

    }
    else {
      this.toasterService.pop('error', '', 'Passwords do not match');
    }
  }
  Back() {
    this.router.navigate([`/dashboard`]);
  }
}
