export class Clients {
    OrganisationId:number;
        OrganisationName:string;
        ContactEmailId:string;
        ContactName:string;
        DomainName:string;
        LogoImage:string;
        SalesforceId:string;
        LastSyncOn:Date;
        SalesforceLastUpdatedOn:Date;
        NoOfUsers:number;
        IsActive:boolean;
        CreatedOn:Date;
        CreatedBy:number;
        UpdatedOn:Date;
        UpdatedBy:number;
        NotAllowedCounsellor:string;
}
