import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToasterService, ToasterConfig } from 'angular2-toaster';
import { LoginService } from 'src/app/login/login.service';
import { User } from 'src/app/admin/users/User';
import {Title} from "@angular/platform-browser";
@Component({
  selector: 'app-mobile-changepassword',
  templateUrl: './mobile-changepassword.component.html',
  styleUrls: ['./mobile-changepassword.component.css'],
  providers: [ToasterService]
})
export class MobileChangepasswordComponent implements OnInit {
  user = new User();
  Password;
  UserId: string;
  ConfirmPassword;
  private router: Router;
  private route: ActivatedRoute;
  private loginService: LoginService;
  private toasterService: ToasterService;
  public toasterconfig: ToasterConfig =
    new ToasterConfig({
      tapToDismiss: true,
      timeout: 3000
    });
  constructor(private formBuilder: FormBuilder, _loginService: LoginService, toasterService: ToasterService, _route: ActivatedRoute, _router: Router,private titleService:Title) {
    this.toasterService = toasterService;
    this.loginService = _loginService;
    this.route = _route;
    this.router = _router;
    this.titleService.setTitle("EAP User");
    console.log(this.route.url);
    this.UserId = this.route.snapshot.children[0].url[1].path;
  }
  ngOnInit() {

  }
  onSubmit() {
    if (this.Password == this.ConfirmPassword) {
      var forgotpassword = {};
      forgotpassword['UserId'] = this.UserId;
      forgotpassword['Password'] = this.Password;
      this.loginService.updatePassword(forgotpassword)
        .subscribe((data: any) => {
          console.log(data);
          if (data.IsSuccess) {

            this.toasterService.pop('success', '', 'Password Changed Successfully');
            this.router.navigate([`/login`]);
          }
        }, (err: HttpErrorResponse) => {
          console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
        });

    }
    else {
      this.toasterService.pop('error', '', 'Passwords do not match');
    }
  }
}