import { Component, OnInit, ViewChild } from '@angular/core';
import { ToasterService, ToasterConfig } from 'angular2-toaster';
import { QuestionService } from '../question/question.service';
import { Router } from '@angular/router';
import { Question } from './question';
import { HttpErrorResponse } from '@angular/common/http';
import { PermahCategory } from './permah-category';
import { QuestionCategory } from './question-category';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { NgxUiLoaderService } from 'ngx-ui-loader'; // Import NgxUiLoaderService
@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.css'],
  providers: [ToasterService]
})
export class QuestionComponent implements OnInit {
  @ViewChild('infoModal') public infoModal: ModalDirective;
  Id: Number;
  orderedlist: any[] = [];
  content: string;
  questionRevert: Question = new Question();
  items: Array<any>;
  pageOfItems: Array<any>;
  pageSize = 10;
  isPageSize = true;
  isListView = true;
  question = new Question;
  private toasterService: ToasterService;
  private questionService: QuestionService;
  private router: Router;
  questionList: Array<Question>;
  permahCategoryList: Array<PermahCategory>;
  questionCategoryList: Array<QuestionCategory>;
  public toasterconfig: ToasterConfig =
    new ToasterConfig({
      tapToDismiss: true,
      timeout: 3000
    });
  constructor(toasterService: ToasterService, _questionService: QuestionService, _router: Router,private ngxService: NgxUiLoaderService) {
    this.toasterService = toasterService;
    this.questionService = _questionService;
    this.router = _router;
  }

  ngOnInit() {
    this.Getquestions();

  }

  ChangeView() {
    this.isListView = this.isListView ? false : true;

  }
  AddNew() {
    const currentQuestion = new Question();
    this.question = Object.assign({}, currentQuestion);
    this.GetQuestionCategory()
    this.GetPermahCategory();
    this.question.PermahCategoryId = 0;
    this.question.CategoryId = 0;

    this.ChangeView();
  }
  showSuccess() {
    this.toasterService.pop('success', '', 'Data saved Successfully !');
  }
  showWarning() {
    this.toasterService.pop('warning', '', 'Data Reset Successfully');
  }
  Save() {
    this.questionService.AddUpdatequestion(this.question)
      .subscribe((data: any) => {
        console.log(data);
        if (data.IsSuccess) {

          this.toasterService.pop('success', '', 'Question Added Successfully');
          this.ChangeView();
          this.Getquestions();

        }
      }, (err: HttpErrorResponse) => {
        console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
      });

  }
  Getquestions() {
    this.ngxService.start();
    this.questionService.Getquestions()
      .subscribe((data: any) => {
        console.log(data);
        if (data.IsSuccess) {

          this.questionList = data.Result;
          this.items = this.questionList;
        }
        this.ngxService.stop();
      }, (err: HttpErrorResponse) => {
        console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
        this.ngxService.stop();
      });
  }
  onChangePage(pageOfItems: Array<any>) {
    console.log(pageOfItems);
    // update current page of items
    this.pageOfItems = pageOfItems;
  }
  FilterGrid(searchText) {
    console.log(searchText);
    if (searchText == '') {
      this.items = this.questionList;
    } else {
      searchText = searchText.replace(/[^\w\s]/gi, ' ').toUpperCase().trim();
      this.items = this.questionList.filter(x =>
        x.QuestionText.replace(/[^\w\s]/gi, ' ').toUpperCase().search(searchText) >= 0
        //  ||
        // x.FullName.replace(/[^\w\s]/gi, ' ').toUpperCase().search(searchText) >= 0 ||
        // x.WorkEmail.replace(/[^\w\s]/gi, ' ').toUpperCase().search(searchText) >= 0 
        // x.MobileNo.replace(/[^\w\s]/gi, ' ').toUpperCase().search(searchText) >= 0

      );
    }

  }
  GetPermahCategory() {
    this.questionService.GetPermahCategory()
      .subscribe((data: any) => {
        console.log(data);
        if (data.IsSuccess) {
          this.permahCategoryList = data.Result;
        }
      }, (err: HttpErrorResponse) => {
        console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
      });
  }
  GetQuestionCategory() {
    this.questionService.GetQuestionCategory()
      .subscribe((data: any) => {
        console.log(data);
        if (data.IsSuccess) {
          this.questionCategoryList = data.Result;
        }
      }, (err: HttpErrorResponse) => {
        console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
      });
  }
  Reset() {

    const itemObj = Object.assign({}, this.questionRevert);
    this.question = itemObj;
    this.question.PermahCategoryId = 0;
    this.question.CategoryId = 0;
  }
  Back() {
    this.ChangeView();
  }
  Edit(QuestionId) {

    this.GetPermahCategory();
    this.GetQuestionCategory();
    this.GetquestionsById(QuestionId);
    const currentQuestion = new Question();
    this.question = Object.assign({}, currentQuestion);
    this.ChangeView();
  }
  GetquestionsById(QuestionId) {
    this.questionService.GetquestionsById(QuestionId)
      .subscribe((data: any) => {
        if (data.IsSuccess) {
          this.question = data.Result;

          this.clone();
        }
      }, (err: HttpErrorResponse) => {
        console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
      });

  }
  clone() {
    this.questionRevert = Object.assign({}, this.question);
  }
  DeletePopUp(QuestionId) {
    this.Id = QuestionId;
    this.infoModal.show();

  }
  Delete(QuestionId) {
    this.infoModal.hide();
    this.questionService.Deletequestion(QuestionId)
      .subscribe((data: any) => {
        console.log(data);
        if (data.IsSuccess) {
          this.Getquestions();
          this.toasterService.pop('success', '', 'Record Deleted Successfully');

        }
      }, (err: HttpErrorResponse) => {


        console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
      });
  }
  pagesize($event) {
    this.isPageSize = false;
    setTimeout(() => {
      this.pageSize = Number($event.target.value);
      this.isPageSize = true;
    }, 500);
    console.log(this.pageOfItems);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.questionList, event.previousIndex, event.currentIndex);
    this.orderedlist=[];
    console.log(this.questionList);
    let count: number = 1;
    let object:any;
    this.questionList.forEach(q => {
       object = {
        "QuestionId": q.QuestionId,
        "DisplayOrder": count++
      }
      this.orderedlist.push(object);
    });
   let obj = {
     'content': this.orderedlist
    }
   

    this.questionService.UpdateorderBy(obj)
      .subscribe((data: any) => {
        console.log(data);
        if (data.IsSuccess) {
          this.Getquestions();
          // this.toasterService.pop('success', 'Success Toaster', 'Record Deleted Successfully');

        }
      }, (err: HttpErrorResponse) => {
        console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
      });
  }
}
