import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import{Authentication}from './authentication';
import { User } from '../admin/users/User';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  constructor(private http: HttpClient) { }
  UserLogin(authentication: Authentication) {
    return this.http.post<any>(`${environment.apiUrl}api/User/UserLogin`, authentication);
  }
  ForgetPassword(object) {
   
    return this.http.post<any>(`${environment.apiUrl}api/User/Forgotpassword`, object);
  }
  updatePassword(forgotpassword)
  {
    return this.http.post<any>(`${environment.apiUrl}api/User/Updatepassword`,forgotpassword);
  }
  CheckUrlisExpiredOrNot(UserId,GUID) {
    return this.http.get<any>(`${environment.apiUserUrl}api/User/CheckUrlisExpireOrNot?UserId=` + UserId+'&GUID='+GUID);  
  }

  get isAuthenticated() {   
        return true;  
  }

  get token() {
    return localStorage.getItem('token');
  }

}
