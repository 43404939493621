import { Component, OnInit } from '@angular/core';
import { ToasterService, ToasterConfig } from 'angular2-toaster';
import { ContentModuleVedioService } from './content-module-vedio.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ContentModuleVedio } from './content-module-vedio';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-content-module-vedio',
  templateUrl: './content-module-vedio.component.html',
  styleUrls: ['./content-module-vedio.component.css'],
  providers: [ToasterService]
})
export class ContentModuleVedioComponent implements OnInit {
  isListView = true;
  picture: any;
  fileToUpload: File;
  files: any;
  imgRevert: string = '';
  image: string;
  noImage: boolean = true;
  URL: string = '';
  contentModuleVedioList: Array<ContentModuleVedio>;
  items: Array<any>;
  pageOfItems: Array<any>;
  pageSize = 5;
  contentModuleVedio: ContentModuleVedio;
  public ContentAreaId:number;
  public ContentTopicId:number;
  public ContentModuleId:number;
  contentModuleVedioRevert: ContentModuleVedio = new ContentModuleVedio();
  private contentModuleVedioService: ContentModuleVedioService;
  private toasterService: ToasterService;
  private route: ActivatedRoute;
  private router: Router;
  public toasterconfig: ToasterConfig =
  new ToasterConfig({
    tapToDismiss: true,
    timeout: 3000
  });
constructor(toasterService: ToasterService,_contentModuleVedioService: ContentModuleVedioService,_router:Router,_route:ActivatedRoute) {
  this.toasterService = toasterService;
  this.contentModuleVedioService = _contentModuleVedioService;
    this.router=_router;
    this.route=_route;
    this.ContentAreaId =parseInt(this.route.snapshot.paramMap.get("id1"));  
    this.ContentTopicId= parseInt(this.route.snapshot.paramMap.get("id2"));
    this.ContentModuleId=parseInt(this.route.snapshot.paramMap.get("id3"));
}

ngOnInit() {
  this.GetContentModuleVedio();
}
ChangeView() {
  this.isListView = this.isListView ? false : true;

}
AddNew() {
  const currentContentModuleVedio = new ContentModuleVedio();
  this.contentModuleVedio = Object.assign({}, currentContentModuleVedio);
  this.URL = "";
  this.ChangeView();
}
showSuccess() {
  this.toasterService.pop('success', '', 'Data saved Successfully !');
}
showWarning() {
  this.toasterService.pop('warning', '', 'Data Reset Successfully');
}

GetContentModuleVedio() {
  this.contentModuleVedioService.GetContentModuleVedio(this.ContentModuleId)
    .subscribe((data: any) => {
      console.log(data);
      if (data.IsSuccess) {
        debugger;
        this.contentModuleVedioList = data.Result;
        this.items = this.contentModuleVedioList;
      }
    }, (err: HttpErrorResponse) => {
      console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
    });
}
onChangePage(pageOfItems: Array<any>) {
  console.log(pageOfItems);
  // update current page of items
  this.pageOfItems = pageOfItems;
}
Save() {
  debugger;
  if (this.URL != '')
    this.contentModuleVedio.Base64Data = this.URL.split(',')[1];
    this.contentModuleVedio.ContentModuleId=this.ContentModuleId;
  this.contentModuleVedioService.AddUpdatecontentModuleVedio(this.contentModuleVedio)
    .subscribe((data: any) => {
      console.log(data);
      if (data.IsSuccess) {
       
          this.toasterService.pop('success', '', 'ContentModuleVedio Added Successfully');
          this.ChangeView();
          this.GetContentModuleVedio();
       
      }
    }, (err: HttpErrorResponse) => {
      console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
    });
}
selectPhoto(file: FileList) {
  debugger;
  this.picture = file.item(0).name;
  this.fileToUpload = file.item(0);
  const File = this.fileToUpload.name;
  this.contentModuleVedio.FileName = File.split('.')[0];
  this.files = File.split('.');
  this.contentModuleVedio.FileType = this.files[this.files.length - 1];
  this.noImage = false;

  // Show Image Preview
  const reader = new FileReader();
  reader.onload = (event: any) => {
    this.URL = event.target.result;
  };
  reader.readAsDataURL(this.fileToUpload);
}

_handleReaderLoaded(readerEvt) {
  const binaryString = readerEvt.target.result;
  this.contentModuleVedio.Base64Data = btoa(binaryString);
}
Edit(ContentVedioId) {
  debugger;
  this.GetContentModuleVedioById(ContentVedioId);
  const currentContentModuleVedio = new ContentModuleVedio();
  this.contentModuleVedio = Object.assign({}, currentContentModuleVedio);
  this.ChangeView();
}
GetContentModuleVedioById(ContentVedioId) {
  this.contentModuleVedioService.GetContentModuleVedioById(ContentVedioId,this.ContentModuleId)
    .subscribe((data: any) => {
      if (data.IsSuccess) {
        this.contentModuleVedio = data.Result;
        if (data.Result.VideoFile != null) {
          this.URL = data.Result.VideoFile;
          this.imgRevert = data.Result.VideoFile;
          this.image = data.Result.VideoFile.split('/');
          this.image = this.image[this.image.length - 1];
          this.contentModuleVedio.VideoFile = this.image;

          this.noImage = false;

        } else {
          this.noImage = true;
        }
        this.clone();
      }
    }, (err: HttpErrorResponse) => {
      console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
    });

}
clone() {
  this.contentModuleVedioRevert = Object.assign({}, this.contentModuleVedio);
}
Reset() {
  debugger;
  const itemObj = Object.assign({}, this.contentModuleVedioRevert);
  this.contentModuleVedio = itemObj;
  if (this.contentModuleVedio.VideoFile == null)
    this.noImage = true;
  if (this.contentModuleVedio.VideoFile != null)
    this.URL = this.imgRevert;
}
Back() {
  this.ChangeView();
}

}
