import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { User } from '../users/User';

@Injectable({
  providedIn: 'root'
})
export class AdminActivationService {

  constructor(private http: HttpClient) { }
  MobileActivateUser(UserId: string) {
    return this.http.get<any>(`${environment.apiUrl}api/User/ActivateUser/${UserId}`);
  }
}