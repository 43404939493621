export class User {
    RowId:number;
    UserId: number;
   // UserName: string;
    Password: string;
    FullName: string;
    FirstName:string;
    LastName:string;
    WorkEmail: string;
    DateOfBirth:Date;
    Email:string;
    Gender:string;
    OrganisationId: number | null;    
    RoleId: number | null;
    MobileNo: string;
    ProfilePhoto: string;
    LastLoggedInOn: Date | null;
    IsManagerRoleApproved: boolean = false;
    LoginProvider: string;
    ProviderId: string;
    ProviderToken: string;
    DeviceId: string;
    DeviceType: string;
    IsActive: boolean;
    CreatedOn: Date | null;
    CreatedBy: number | null;
    UpdatedOn: Date | null;
    UpdatedBy: number | null;
    OrganisationName: string;
    RoleName: string;
    Base64Data: any;
    FileName: any;
    FileType: any;
    LoginType: string;
    ACEClientId: string;
    TimezoneName:string;
    State:string;
    AllowedAppts: number | null;
    UsedAppts: number | null;
    RemainAppts: number | null;
    constructor() {
        this.UserId = 0;
      //  this.UserName = null;
        this.Password = null;
        this.FullName = null;
        this.FirstName=null;
        this.LastName=null;
        this.Email=null;
        this.WorkEmail = null;
        this.OrganisationId = null;
        this.RoleId = null;
        this.MobileNo = null;
        this.ProfilePhoto = null;
        this.LastLoggedInOn = null;
        this.IsManagerRoleApproved = false;
        this.LoginProvider = null;
        this.ProviderId = null;
        this.ProviderToken = null;
        this.DeviceId = null;
        this.DeviceType = null;
        this.IsActive = null;
        this.CreatedOn = null;
        this.CreatedBy = null;
        this.UpdatedOn = null;
        this.UpdatedBy = null;
        this.OrganisationName = null;
        this.RoleName = null;
        this.LoginType = null;
        this.ACEClientId=null;
        this.TimezoneName=null;
        this.State=null;
        this.AllowedAppts=null;
        this.UsedAppts=null;
        this.RemainAppts=null;
    }

}
