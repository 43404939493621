import { Component, OnInit } from '@angular/core';
import { ToasterService, ToasterConfig } from 'angular2-toaster';
import { ClientsService } from './clients.service';
import{Clients}from './clients';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader'; // Import NgxUiLoaderService
import { AceService } from 'src/app/ace-intergation/ace.service';

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.css'],
  providers: [ToasterService]
})
export class ClientsComponent implements OnInit {
  clients=new Clients();
  items: Array<any>;
  pageOfItems: Array<any>;
  pageSize = 10;
  isListView = true;
  isPageSize = true;
  selectedItemsaddstring:any;
  clientList: Array<Clients>;

  dropdownList:Array<any> = [];
    selectedItems:Array<any> = [];
    selectedItemsadd:Array<any> = [];
    dropdownSettings = {};
    CounsellorsList:Array<any>;

  private toasterService: ToasterService;
  private clientsService: ClientsService;
  public toasterconfig: ToasterConfig =
    new ToasterConfig({
      tapToDismiss: true,
      timeout: 3000
    });
  constructor(private _aceService:AceService,toasterService: ToasterService,_clientsService: ClientsService,private ngxService: NgxUiLoaderService) {
    this.toasterService = toasterService;
    this.clientsService = _clientsService;
  }

  ngOnInit() {
   // debugger;
    this.GetClients();
this.dropdownSettings = { 
          singleSelection: false, 
          text:"Select Counsellors",
          selectAllText:'Select All',
          unSelectAllText:'UnSelect All',
          enableSearchFilter: false,
          classes:"myclass custom-class",
          enableFilterSelectAll:false,
          autoPosition:false,
          position:'top',
          badgeShowLimit:3
        };            

  }

  onItemSelect(item:any){
    //debugger;
    console.log(item);
    this.selectedItemsadd.push(item.id)
    this.selectedItemsaddstring =this.selectedItemsadd.toString()
}
OnItemDeSelect(item:any){
 //debugger;
    console.log(item);
    console.log(this.selectedItems);
    const index: number = this.selectedItemsadd.indexOf(item.id);
    if (index !== -1) {
        this.selectedItemsadd.splice(index, 1);
    }
    this.selectedItemsaddstring =this.selectedItemsadd.toString(); 
}
onSelectAll(items: any){
    this.dropdownList=this.dropdownList;
    console.log(items);
    this.selectedItemsadd=[];
    this.selectedItemsaddstring=[];
    for(let i = 0; i < items.length; i++){
      this.selectedItemsadd.push(items[i].id);
      this.selectedItemsaddstring=this.selectedItemsadd.toString()
    }
    console.log(this.selectedItemsaddstring);
}
onDeSelectAll(items: any){
    this.dropdownList=this.dropdownList;
    console.log(items);
    this.selectedItemsadd=[];
    this.selectedItemsaddstring=[];
}

  ChangeView() {
    this.isListView = this.isListView ? false : true;

  }
  AddNew() {
    this.ChangeView();
  }
  showSuccess() {
    this.toasterService.pop('success', '', 'Data synchronized Successfully !');
  }
  showWarning() {
    this.toasterService.pop('warning', '', 'Data Reset Successfully');
  }
  Save() {
    this.showSuccess();
    setTimeout(() => {
      this.ChangeView();
    }, 3000);   
  }
  Reset() {
    this.showWarning();
    setTimeout(() => {
      //this.ChangeView();
    }, 3000);   
  }
  GetClients()
  {
    this.ngxService.start();
    this.clientsService.GetClients()
      .subscribe((data: any) => {
        if (data.IsSuccess) {
          this.clientList = data.Result;
          this.items = this.clientList;
        }
        this.ngxService.stop();
      }, (err: HttpErrorResponse) => {
        console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
        this.ngxService.stop();
      });
  }
  FilterGrid(searchText) {
    if (searchText == '') {
      this.items = this.clientList;
    } else {
      searchText = searchText.replace(/[^\w\s]/gi, ' ').toUpperCase().trim();
      this.items = this.clientList.filter(x =>
  
        ((x.OrganisationName?true:false) &&  (x.OrganisationName.replace(/[^\w\s]/gi, ' ').toUpperCase().search(searchText) >= 0))||
        ((x.ContactEmailId?true:false) &&  (x.ContactEmailId.replace(/[^\w\s]/gi, ' ').toUpperCase().search(searchText) >= 0))||
        ((x.ContactName?true:false) &&  (x.ContactName.replace(/[^\w\s]/gi, ' ').toUpperCase().search(searchText) >= 0))||
        ((x.DomainName?true:false) &&  (x.DomainName.replace(/[^\w\s]/gi, ' ').toUpperCase().search(searchText) >= 0))
   

      );
    }

  }
  onChangePage(pageOfItems: Array<any>) {
    console.log(pageOfItems);
    // update current page of items
    this.pageOfItems = pageOfItems;
  }
  View(OrganisationId)
  {
    this.selectedItems=[];
    this.GetClientById(OrganisationId);
    this.GetAllActiveCounsellors();

  }
  GetAllActiveCounsellors(){
    this.dropdownList=[];
    this.clientsService.GetAllActiveCounsellors()
    .subscribe((data:any)=>{
      console.log(data);
      if(data.IsSuccess){
        this.CounsellorsList=data.Result;
        for (let i = 0; i < this.CounsellorsList.length; i++) {
          this.dropdownList.push(
            {"id":this.CounsellorsList[i].UserId,"itemName":this.CounsellorsList[i].FullName}
          );
        }

        this.dropdownList=this.dropdownList.map((x)=>{
          return{
            "id":x.id,"itemName":x.itemName
          }
        });
   var names = this.clients.NotAllowedCounsellor;
   if(names != null && names !='undefined' && names !=""){
    
    var nameArr = names.split(',');
    // this.selectedItemsadd=nameArr;
    this.selectedItemsadd=nameArr.map(Number);
    this.selectedItemsaddstring =this.selectedItemsadd.toString()
    for (let k = 0; k < nameArr.length; k++) {
      var marvelHeroes =  this.CounsellorsList.filter(function(hero) {
        return hero.UserId == nameArr[k];
      });
      this.selectedItems.push(
        // {"id":nameArr[k],"itemName":marvelHeroes[0].FullName}
        {"id":marvelHeroes[0].UserId,"itemName":marvelHeroes[0].FullName}
      );
    }
   }
   else{
    this.selectedItems=[];
    this.selectedItemsadd=[];
   }
  
      }
    }, (err: HttpErrorResponse) => {
      console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
    });
  }
  GetClientById(OrganisationId) {
    debugger;
    this.clientsService.GetClientOrganisationById(OrganisationId)
      .subscribe((data: any) => {
        if (data.IsSuccess) {
          this.clients = data.Result;
          this.ChangeView();
          console.log(this.clients);
        }
      }, (err: HttpErrorResponse) => {
        console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
      });

  }
  saveCounsollernotshown(){
    var OrganisationId = this.clients.OrganisationId;
    this._aceService.saveCounsollernotshown(OrganisationId, this.selectedItemsaddstring, this.clients.OrganisationName)
    .subscribe((data: any) => {
      this.toasterService.pop('success', '', 'Updated Successfully !');
    
    }, (err: HttpErrorResponse) => {
      console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
      
    });
  }
  Back() {
    this.ChangeView();
  }
  Sync()
  {this.ngxService.start();
    this._aceService.Loadcustomers()
    .subscribe((data: any) => {    
      if (data.IsSuccess) {
        this.ngxService.stop();
        this.GetClients();
        this.DeleteCustomer();
        this.showSuccess();
    setTimeout(() => {
      this.ChangeView();
    }, 3000); 
        
      }
      else{
        this.ngxService.stop();
        this.toasterService.pop('warrning', '', 'Records Not Found.');
        setTimeout(() => {
          this.ChangeView();
        }, 3000); 
        
          
       // this.toasterService.pop("Records Not Found.")
      }
    }, (err: HttpErrorResponse) => {
     this.toasterService.pop('error', '', 'Problem with the sevice. Please try later');
     // console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
     this.ngxService.stop();
    });
 

  }
  pagesize($event) {
    this.isPageSize = false;
    setTimeout(() => {     
      this.pageSize = Number($event.target.value);
      this.isPageSize = true;
    }, 500);
   console.log( this.pageOfItems);
  }
  DeleteCustomer()
  {
    
    this._aceService.DeleteCustomer()
      .subscribe((data: any) => {
      
      
      }, (err: HttpErrorResponse) => {
        console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
        
      });
  }
}
