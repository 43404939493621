import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import{ContentAreas}from './content-areas';


@Injectable({
  providedIn: 'root'
})
export class ContentAreasService {

  constructor(private http: HttpClient) { }
  GetContentAreas() {
    return this.http.get<any>(`${environment.apiUrl}api/ContentArea/GetContentAreas`);
  }
  GetContentAreaById(Id: number) {
    return this.http.get<any>(`${environment.apiUrl}api/ContentArea/GetContentAreaById?ContentAreaId=` + Id);
  }


  AddUpdateContentArea(contentAreas: ContentAreas) {
      return this.http.post<any>(`${environment.apiUrl}api/ContentArea/AddUpdateContentArea`, contentAreas);
    }

    DeleteContentArea(Id:number)
    {
      //return this.http.post<number>(`${environment.apiUrl}api/ContentArea/DeleteContentArea?ContentAreaId=` + Id);
      return this.http.get<any>(`${environment.apiUrl}api/ContentArea/DeleteContentArea?ContentAreaId=` + Id);
    }
}
