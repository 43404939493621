import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {Question}from './question';
@Injectable({
  providedIn: 'root'
})
export class QuestionService {
  private url = '';
  constructor(private http: HttpClient) { }
  Getquestions() {
    return this.http.get<any>(`${environment.apiUrl}api/Question/GetQuestions`);
  }
  GetquestionsById(Id:number) {
    return this.http.get<any>(`${environment.apiUrl}api/Question/GetQuestionById?QuestionId=` + Id);
  }
  AddUpdatequestion(question: Question) {
    return this.http.post<any>(`${environment.apiUrl}api/Question/AddUpdateQuestion`, question);
  }
  Deletequestion(Id:number)
  {
    //return this.http.post<number>(`${environment.apiUrl}api/ContentArea/DeleteContentArea?ContentAreaId=` + Id);
    return this.http.get<any>(`${environment.apiUrl}api/Question/DeleteQuestion?QuestionId=` + Id);
  }
  GetPermahCategory() {
    return this.http.get<any>(`${environment.apiUrl}api/Question/GetPermahCategory`);
  }
  GetQuestionCategory() {
    return this.http.get<any>(`${environment.apiUrl}api/Question/GetQuestionCategory`);
  }
  UpdateorderBy(object)
  {
    
  //   this.url = environment.apiUrl+ 'api/Question/UpdateorderBy?content='+ content;
  // return this.http.post(this.url, content)
    return this.http.post<any>(`${environment.apiUrl}api/Question/UpdateorderBy`, object);
  }
}
