import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {ChatTemplate} from './ChatTemplate'

@Injectable({
    providedIn: 'root'
  })
  export class ChatTemplatesService{
    constructor(private http: HttpClient) { 
        
    }
    GetChatMessages() {
        return this.http.get<any>(`${environment.apiUrl}api/ChatTemplates/GetAllChatMessages`);
      }
      AddUpdateMessageTemplate(obj: any) {
        return this.http.post<any>(`${environment.apiUrl}api/ChatTemplates/AddUpdateMessageTemplate`, obj);
      }
      DeleteTemplate(Id:number)
      {
        return this.http.get<any>(`${environment.apiUrl}api/ChatTemplates/DeleteTemplate?ChatTemlateID=` + Id);
      }
  }