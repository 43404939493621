import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';

import { from } from 'rxjs';
import { LoginComponent } from './login/login.component';
import { UserManagementComponent } from './admin/user-management/user-management.component';
import { LayoutsComponent } from './layouts/layouts.component';
import { ClientsComponent } from './admin/clients/clients.component';
import { SystemUsersComponent } from './admin/system-users/system-users.component';
import { ContentAreasComponent } from './admin/content-areas/content-areas.component';
import { ContentTopicsComponent } from './admin/content-topics/content-topics.component';
import { ContentModulesComponent } from './admin/content-modules/content-modules.component';
import { UsersComponent } from './admin/users/users.component';
import { FaqComponent } from './admin/faq/faq.component';
import { ContentModuleVedioComponent } from './admin/content-module-vedio/content-module-vedio.component';
import { QuestionComponent } from './admin/question/question.component';
import { CmsPageComponent } from './admin/cms-page/cms-page.component';
import { ManageCounsellersComponent } from './admin/manage-counsellers/manage-counsellers.component';
import { ForgetpasswordComponent } from './admin/forgetpassword/forgetpassword.component';
import { AuthGuard } from './guards/authguard';
import { ChangepasswordComponent } from './admin/changepassword/changepassword.component';
import { AceIntergationComponent } from './ace-intergation/ace-intergation.component';
import { TestResultComponent } from './admin/test-result/test-result.component';
import { TestResultDeatilsComponent } from './admin/test-result/test-result-deatils/test-result-deatils.component';
import {ActivationComponent} from './admin/activation/activation.component';
import{AdminActivationComponent} from './admin/admin-activation/admin-activation.component';
import{MobileChangepasswordComponent}from './admin/mobile-changepassword/mobile-changepassword.component';
import { TopicContentComponent } from './admin/Topic-ContentManagement/topic-content/topic-content.component';
import { ModuleContentComponent } from './admin/Topic-ContentManagement/module-content/module-content.component';
import { ModulesComponent } from './admin/Module-ContentManagement/modules/modules.component';
import{EmailTemplateComponent}from './admin/email-template/email-template.component';
import { ChatTemplatesComponent } from './admin/chat-templates/chat-templates.component';
import { ServerMaintenanceComponent } from './admin/server-maintenance/server-maintenance.component';
const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'dashboard', component: HomeComponent, canActivate: [AuthGuard] },
  { path: 'admin-user', component: SystemUsersComponent, canActivate: [AuthGuard] },
  { path: 'Customers', component: ClientsComponent, canActivate: [AuthGuard] },
  { path: 'client-users', component: UsersComponent, canActivate: [AuthGuard] },
  { path: 'content-areas', component: ContentAreasComponent, canActivate: [AuthGuard] },
  { path: 'content-topics/:id', component: ContentTopicsComponent, canActivate: [AuthGuard] },
  { path: 'content-modules/:id1/:id2', component: ContentModulesComponent, canActivate: [AuthGuard] },
  { path: 'faqs', component: FaqComponent, canActivate: [AuthGuard] },
  { path: 'content-module-vedio/:id1/:id2/:id3', component: ContentModuleVedioComponent, canActivate: [AuthGuard] },
  { path: 'question', component: QuestionComponent, canActivate: [AuthGuard] },
  { path: 'cms', component: CmsPageComponent, canActivate: [AuthGuard] },
  { path: 'manage-counseller', component: ManageCounsellersComponent, canActivate: [AuthGuard] },
  { path: 'changepassword', component: ChangepasswordComponent, canActivate: [AuthGuard] },
  { path: 'usertestresult', component: TestResultComponent, canActivate: [AuthGuard] },
  { path: 'test-result-details/:id', component: TestResultDeatilsComponent, canActivate: [AuthGuard] },
  {path:'topic-content',component:TopicContentComponent,canActivate: [AuthGuard]},
  { path: 'forgetpassword/:id1/:id2', component: ForgetpasswordComponent },
  { path: 'admin-users/:id', component: AdminActivationComponent},
  { path: 'activation/:id1/:id1', component: ActivationComponent },
  { path: 'ace', component: AceIntergationComponent, canActivate: [AuthGuard] },
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'mobilechangepsw/:id', component: MobileChangepasswordComponent },
  {path:'topic-module/:id1/:id2',component:ModuleContentComponent,canActivate:[AuthGuard]},
  {path:'modules',component:ModulesComponent,canActivate: [AuthGuard]},
  {path:'email-template',component:EmailTemplateComponent,canActivate: [AuthGuard]},
  {path:'chat-templates',component:ChatTemplatesComponent,canActivate: [AuthGuard]},
  {path:'server-maintenance',component:ServerMaintenanceComponent,canActivate: [AuthGuard]},


];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
