import { DecimalPipe } from "@angular/common";

export class Users {
    NewUserCount:number;
    UnInstallUserCount:number; 
    NetNewUserCount:number;
    ActiveUserCount:number;
    AvgSessionTime:number;
    TotalAppSessions:number;
    AvgSessionPerEmp:number;
    AvgSessionTimePerEmpMins:number;
    TotalTimePerEmp:number;
    AppLoadTime:number;
    LastMonthNetNewUserCount:DecimalPipe;
    LastMonthUserCount:DecimalPipe; 
    LastMonthUnInstallUserCount:DecimalPipe;
    LastMonthActiveUserCount:DecimalPipe;
    LastMonthAvgSessionTime:DecimalPipe;
    LastMonthTotalAppSessions:DecimalPipe;
    LastMonthAvgSessionPerEmp:DecimalPipe;
    LastMonthAvgSessionTimePerEmpMins:DecimalPipe;
    LastMonthTotalTimePerEmp:DecimalPipe;
    LastMonthAppLoadTime:DecimalPipe;
    LastMontTotalOnlineBooking:DecimalPipe;
    LastMontTotalNumberOfSceduleChatSession:DecimalPipe;
    LastMontTotalNumberOfInstantChatSession:DecimalPipe;
    LastMontTotalNumberOfChatSession:DecimalPipe;
    LastMontTotalOnlineTimeAvgPerEmp:DecimalPipe;
    LastMontTotalNumberOfCancelledBooking:DecimalPipe;
    TotalOnlineBookings:number;
    TotalSceduleChats:number; 
    TotalInstantChats:number;
    TotalOnlineChats:number;
    AvgOnlineChatPerEmpMins:number;
    TotalCancelledChats:number;
 
}
