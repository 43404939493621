import { Component, OnInit } from '@angular/core';
import { ToasterService, ToasterConfig } from 'angular2-toaster';
import { Router } from '@angular/router';
import { TestResultService } from './test-result.service';
import { TestResult } from './test-result';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-test-result',
  templateUrl: './test-result.component.html',
  styleUrls: ['./test-result.component.css'],
  providers: [ToasterService]
})
export class TestResultComponent implements OnInit {
  pageOfItems: Array<any>;
  pageSize = 10;
  isPageSize = true;
  isListView = true;
  private toasterService: ToasterService;
  public toasterconfig: ToasterConfig =
    new ToasterConfig({
      tapToDismiss: true,
      timeout: 3000
    });
    private router: Router;
    private testResultService: TestResultService;
    testResultList: Array<TestResult>;
    items: Array<any>;
  constructor(toasterService: ToasterService,_router:Router,_testResultService: TestResultService,) {
    this.toasterService = toasterService;
    this.testResultService = _testResultService;
    this.router=_router;
  }

  ngOnInit() {
    this.GetTestResult();
  }
  ChangeView() {
    this.isListView = this.isListView ? false : true;

  }
  AddNew() {
    this.ChangeView();
  }
  showSuccess() {
    this.toasterService.pop('success', '', 'Data saved Successfully !');
  }
  showWarning() {
    this.toasterService.pop('warning', '', 'Data Reset Successfully');
  }
  Save() {
    this.showSuccess();
    setTimeout(() => {
      this.ChangeView();
    }, 3000);   
  }
  Reset() {
    this.showWarning();
    setTimeout(() => {
      //this.ChangeView();
    }, 3000);   
  }
  GetTestResult(){
    this.testResultService.GetTestResult()
    .subscribe((data: any) => {
      console.log(data);
      if (data.IsSuccess) {
        this.testResultList = data.Result;
        this.items = this.testResultList;
      }
    }, (err: HttpErrorResponse) => {
      console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
    });
  }
  FilterGrid(searchText) {
    console.log(searchText);
    if (searchText == '') {
      this.items = this.testResultList;
    } else {
      searchText = searchText.replace(/[^\w\s]/gi, ' ').toUpperCase().trim();
      this.items = this.testResultList.filter(x =>
        ((x.FullName?true:false) &&  (x.FullName.replace(/[^\w\s]/gi, ' ').toUpperCase().search(searchText) >= 0))
       
  
      );
    
    }
  
  }
  onChangePage(pageOfItems: Array<any>) {
    console.log(pageOfItems);
    // update current page of items
    this.pageOfItems = pageOfItems;
  }
  public Details(CheckTestId) {
   // this.router.navigate([`test-result-details/` + CheckTestId + ``]);
    this.router.navigate([`test-result-details/` + CheckTestId + ``]);
  }
}