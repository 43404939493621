export class Question {
    QuestionId:number;
    QuestionText:string; 
    Weigthage:number;
    CategoryId:number;
    QuestionCategory:string;
    PermahCategoryId:number;
    PermahCategory:string;
    DisplayOrder:number;
    IsActive:boolean;
    CreatedOn:Date;
    CreatedBy:number;
    UpdatedOn:Date;
    UpdatedBy:number;

}
