import { Component, OnInit } from '@angular/core';
import { ToasterService, ToasterConfig } from 'angular2-toaster';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminActivationService } from './admin-activation.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-admin-activation',
  templateUrl: './admin-activation.component.html',
  styleUrls: ['./admin-activation.component.css']
})
export class AdminActivationComponent implements OnInit {

  private toasterService: ToasterService;
  private activationService: AdminActivationService;
  private router: Router;
  private route: ActivatedRoute;
  UserId: string;
  Message:string;
  public toasterconfig: ToasterConfig =
    new ToasterConfig({
      tapToDismiss: true,
      timeout: 3000
    });

  constructor(_route: ActivatedRoute, _router: Router,_activationService: AdminActivationService) {
    this.route = _route;
    this.router = _router;
    this.activationService=_activationService;
    console.log(this.route.url);
    this.UserId = this.route.snapshot.children[0].url[1].path;
   }

  ngOnInit() {
    this.activationService.MobileActivateUser(this.UserId)
    .subscribe((data: any) => {
      if (data.IsSuccess) {
        if(data.Message=="User successfully activated")
        {
       this.Message="Welcome to EAP Admin Portal Autogenerated Password is sent to your mail..";
        }
       else if(data.Message=='User already activated')
       {
       this.Message="User already activated";
       console.log(this.Message);
       }
      }
    }, (err: HttpErrorResponse) => {
      console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
    });

  }

}

