export class ContentAreas {
    ContentAreaId:number;
    AreaName:string;
    Summary:string;
    Summarywithsplit:string;
    IconImage:string;
    NoOfTopics:number;
    NoOfModules:number;
    IsActive:boolean;
    CreatedOn:Date;
    CreatedBy:number; 
    UpdatedOn:Date;
    UpdatedBy:number;

    Base64Data: any;
    FileName: any;
    FileType: any;
}

