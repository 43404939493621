export class CmsPage {
    CMSPageId:number;
    CMSPageCode:string; 
    CMSPageName:string;
    ContentText:string;
    IsActive:boolean;
    CreatedOn:Date;
    CreatedBy:number;
    UpdatedOn:Date;
    UpdatedBy:number;
}
