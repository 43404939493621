
import { Component, OnInit, ViewChild } from '@angular/core';
import { ToasterService, ToasterConfig } from 'angular2-toaster';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { NgxUiLoaderService } from 'ngx-ui-loader'; // Import NgxUiLoaderService
import { EmailTemplateService } from './email-template.service';
import { EmailTemplate } from './email-template';
@Component({
  selector: 'app-email-template',
  templateUrl: './email-template.component.html',
  styleUrls: ['./email-template.component.css'],
  providers: [ToasterService]
})
export class EmailTemplateComponent implements OnInit {
  @ViewChild('infoModal') public infoModal: ModalDirective;
  cmsPageRevert: EmailTemplate = new EmailTemplate();
  CmsPage: EmailTemplate;
  cmsPageList: Array<EmailTemplate>;
  Id: number;
  isListView = true;
  public message:string="";
  private toasterService: ToasterService;
  private router: Router;
  items: Array<any>;
  pageOfItems: Array<any>;
  pageSize = 10;
  isPageSize = true;
  ckeConfig: any;
  private cmsPageService: EmailTemplateService;
  public toasterconfig: ToasterConfig =
  new ToasterConfig({
    tapToDismiss: true,
    timeout: 3000
  });
  constructor(_cmsPageService: EmailTemplateService,toasterService: ToasterService,_router:Router,private ngxService: NgxUiLoaderService) { 
    this.toasterService = toasterService;
    this.cmsPageService = _cmsPageService;
    this.router=_router;
  }

  ngOnInit() {
  this.GetEmailTemplates();
    this.ckeConfig = {
      forcePasteAsPlainText: true
    };
  }
  ChangeView() {
    this.isListView = this.isListView ? false : true;
  
  }
  GetEmailTemplates() {
  this.ngxService.start();
  this.cmsPageService.GetTemplate().subscribe((data: any) => {
      console.log(data);
      if (data.IsSuccess) {
        this.cmsPageList = data.Result;
        this.items = this.cmsPageList;
      }
      this.ngxService.stop();
    }, (err: HttpErrorResponse) => {
      console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
      this.ngxService.stop();
    });
}
onChangePage(pageOfItems: Array<any>) {
  console.log(pageOfItems);
  // update current page of items
  this.pageOfItems = pageOfItems;
}
Edit(CMSPageId) {
  this.GetCMSpageById(CMSPageId);
  const currentCmsPage = new EmailTemplate();
  this.CmsPage = Object.assign({}, currentCmsPage);
  this.ChangeView();
}
GetCMSpageById(Id) {
  this.ngxService.start();
  this.cmsPageService.GetEmailTemplateById(Id)
    .subscribe((data: any) => {
      if (data.IsSuccess) {
        this.CmsPage = data.Result;
        this.CmsPage.Body=data.Result.Body;
        this.clone();
      }
      this.ngxService.stop();
    }, (err: HttpErrorResponse) => {
      console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
      this.ngxService.stop();
    });

}
clone() {
  this.cmsPageRevert = Object.assign({}, this.CmsPage);
}
Save() {
  this.cmsPageService.AddUpdateEmailTemplate(this.CmsPage)
    .subscribe((data: any) => {
      console.log(data);
      if (data.IsSuccess) {
       
          this.toasterService.pop('success', '', 'updated Successfully');
          this.ChangeView();
          this.GetEmailTemplates();
       
      }
    }, (err: HttpErrorResponse) => {
      this.message=err.error.Message;
      if(this.message.includes("Cannot insert duplicate key in object 'dbo.CMSPages'"))
      this.toasterService.pop('error', 'Error Toaster', 'PageCode Is Already Exist');
      console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
    });
}
Back() {
  this.ChangeView();
}
FilterGrid(searchText) {
  console.log(searchText);
  if (searchText == '') {
    this.items = this.cmsPageList;
  } else {
    searchText = searchText.replace(/[^\w\s]/gi, ' ').toUpperCase().trim();
    this.items = this.cmsPageList.filter(x =>
      x.TemplateName.replace(/[^\w\s]/gi, ' ').toUpperCase().search(searchText) >= 0

    );
  }

}
pagesize($event) {
  this.isPageSize = false;
  setTimeout(() => {     
    this.pageSize = Number($event.target.value);
    this.isPageSize = true;
  }, 500);
 console.log( this.pageOfItems);
}
}
