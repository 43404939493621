import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginService } from './login/login.service';
import { Router } from '@angular/router';
import { RefreshTokenHttpService } from './refreshToken.service';
import jwtDecode from "jwt-decode";
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  constructor(private injector: Injector, private router: Router, private service: RefreshTokenHttpService) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const auth = this.injector.get(LoginService);

    if (auth.isAuthenticated) {
      // console.log('token:' + auth.token);
      if (this.GetTokenNotRequiredAPI(request.url) == false) {

        if (auth.token == null || auth.token == "") {
          localStorage.clear();
          this.router.navigate(['/login']);
        }
        else {

          var tokenInfo = jwtDecode(auth.token);
          let expTime: number = this.TokenIdentity(tokenInfo);
          var date = new Date(0);
          date.setUTCSeconds(expTime);
          if (date.valueOf() < new Date().valueOf()) {
            this.service.RefreshToken();
          }

          request = request.clone({
            headers: request.headers.set('Authorization', 'Bearer ' + auth.token)
          });

          return next.handle(request);
        }
      }
      else {
        return next.handle(request);
      }
    }
  }

  TokenIdentity(tokenInfo: any) {
    return tokenInfo.exp;
  }

  GetTokenNotRequiredAPI(request) {
    if (request.includes("/GetCustomers") ||
      request.includes("/GetActivityTypes") ||
      request.includes("/GetProfessionals") ||
      request.includes("/GetServiceTypes") ||
      request.includes("/UpdateDashBoardDetails") ||
      request.includes("/UserLogin") ||
      request.includes("/Forgotpassword") ||
      request.includes("/Updatepassword") ||
      request.includes("/ActivateUser") || 
      request.includes("/DeleteCustomers") || 
      request.includes("/CheckUrlisExpireOrNot") )
      return true;
    else
      return false;
  }
}
