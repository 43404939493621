//import { SrvRecord } from "dns";

//import { ContentModuleVideos } from './content-module-videos';

export class ContentModules {
    ContentModuleId:number;
    ModuleTitle:string;
    ContentAreaId:number;
    ContentAreaName:string;
    ContentTopicId:number;
    ContentTopicName:string;
    Summary:string;
    Summarywithsplit:string;
    Keywords:string;
    ContentText:string;
    IconImage:string ;
    VideoTitle:string;
    VideoFile:String;
    NoOfViews:number;
    NoOfLIkes:number;
    VisibleToEmp:boolean;
    VisibleToMgr:boolean;
    IsActive:boolean;
    CreatedOn:Date;
    CreatedBy:number;
    UpdatedOn:Date;
    UpdatedBy:number;
    videoVisibleTo:boolean;
    AudioVisibleTo:boolean;
    Base64Data: any;
    FileType: any;

    Base64DataForVedio: any;
    VedioType: any;
    Base64DataForAudio: any;
    AudioFile:String;
    AudioType:any;

}
