import { Component, OnInit, ViewChild } from '@angular/core';
import { ToasterService, ToasterConfig } from 'angular2-toaster';
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from '../users/user.service';
import { User } from '../users/User';
import { from } from 'rxjs';
import {ModalDirective} from 'ngx-bootstrap/modal';
import { NgxUiLoaderService } from 'ngx-ui-loader'; // Import NgxUiLoaderService

@Component({
  selector: 'app-system-users',
  templateUrl: './system-users.component.html',
  styleUrls: ['./system-users.component.css'],
  providers: [ToasterService]
})
export class SystemUsersComponent implements OnInit {
  @ViewChild('infoModal') public infoModal: ModalDirective;
  isListView = true;
  private toasterService: ToasterService;
  private userService: UserService;
  userList: Array<User>;
  user: User = new User();
  userRevert: User = new User();
  public message: string = "";
  public toasterconfig: ToasterConfig =
    new ToasterConfig({
      tapToDismiss: true,
      timeout: 3000
    });
    items: Array<any>;
    pageOfItems: Array<any>;
    
    pageSize=10;
    picture: any;
    fileToUpload: File;
    files: any;
    imgURL: string = '';
    image: string;
    imgRevert:string='';
    noImage: boolean = true;
    isPageSize = true;
  constructor(_toasterService: ToasterService, _userService: UserService,private ngxService: NgxUiLoaderService) {
    this.toasterService = _toasterService;
    this.userService = _userService;
  }

  ngOnInit() {
    this.GeUsers();
    this.loadScript();
  }
  public loadScript() {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = '../assets/coreui/main.js';
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }
  GeUsers() {
    this.ngxService.start();
    this.userService.GetUsersByRole(3)
      .subscribe((data: any) => {
        console.log(data);
        if (data.IsSuccess) {
          this.userList = data.Result;
          this.items = this.userList;
        }
        this.ngxService.stop();
      }, (err: HttpErrorResponse) => {
        console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
        this.ngxService.stop();
      });
  }
  clone() {
    this.userRevert = Object.assign({}, this.user);
    console.log(this.user);
  }
  GeUser(Id,isDelete) {
    const currentUser = this.userList.find(x => x.UserId === Id);
    this.user = Object.assign({}, currentUser);

    this.clone();
    if(!isDelete)
    {
      // this.imgURL =  this.user.ProfilePhoto;
      // this.image =  this.user.ProfilePhoto.split('/');
      // this.image = this.image[this.image.length - 1];
      // this.contentAreas.IconImage = this.image;
      if ( this.user.ProfilePhoto != null) {
        this.imgURL =  this.user.ProfilePhoto;
        this.imgRevert=this.imgURL;
       var imageData=  this.user.ProfilePhoto.split('/');
        this.image = imageData[imageData.length - 1];
        this.user.ProfilePhoto = this.image;

        this.noImage = false;
        

      } else {
        this.noImage = true;
      }
    this.ChangeView();
    //console.log(this.user);
  }
}
  ChangeView() {
    this.isListView = this.isListView ? false : true;

  }
  AddNew() {
    const currentUser = new User();
    this.user = Object.assign({}, new User());
    this.userRevert= Object.assign({}, currentUser);
    this.userRevert.IsActive=true;
    this.noImage = true;
    console.log(this.user);
    this.user.IsActive = false;
    this.user.LastLoggedInOn = null;
    this.user.RoleId = 3;
    this.ChangeView();

    console.log(currentUser);
  }
  onChangePage(pageOfItems: Array<any>) {
    console.log(pageOfItems);
    // update current page of items
    this.pageOfItems = pageOfItems;
  }
  showSuccess() {
    this.toasterService.pop('success', '', 'Data saved Successfully !');
  }
  showWarning() {
    this.toasterService.pop('warning', '', 'Data Reset Successfully');
  }

  Reset() {

    const itemObj = Object.assign({}, this.userRevert);
    this.user = itemObj;
    if (this.user.ProfilePhoto == null)
    this.noImage = true;
  if (this.user.ProfilePhoto != null)
    this.imgURL = this.imgRevert;

  }
  Back() {
    this.ChangeView();
  }

  Save() {
    this.ngxService.start();
    if (this.imgURL != '')
    this.user.Base64Data = this.imgURL.split(',')[1];
    this.userService.AddUpdateAdminUser(this.user)
      .subscribe((data: any) => {
        console.log(data);
        if (data.IsSuccess) {
          this.showSuccess();
          this.GeUsers();
          setTimeout(() => {
            this.ChangeView();
          }, 3000);

        }
        else{
         
          if(data.Message=="Email Already Exists")
          this.toasterService.pop('error', '', 'Email Is Already Exist');
         
        }
        this.ngxService.stop();
      }, (err: HttpErrorResponse) => {
        console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
        this.ngxService.stop();
      });
  }

  Delete() {   
    this.user.IsActive = false;
    this.userService.DeleteUser(this.user)
    .subscribe((data: any) => {
      console.log(data);
      if (data.IsSuccess) {
        this.toasterService.pop('success', '', 'Record Deleted Successfully');
        this.GeUsers();
        this.infoModal.hide();
     
      }
    }, (err: HttpErrorResponse) => {
      console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
    });
  }
  FilterGrid(searchText) {
    console.log(searchText);
    if (searchText == '') {
      this.items = this.userList;
    } else {
      searchText = searchText.replace(/[^\w\s]/gi, ' ').toUpperCase().trim();
      this.items = this.userList.filter(x =>
        //x.UserName.replace(/[^\w\s]/gi, ' ').toUpperCase().search(searchText) >= 0 ||
        x.FullName.replace(/[^\w\s]/gi, ' ').toUpperCase().search(searchText) >= 0 ||
        x.Email.replace(/[^\w\s]/gi, ' ').toUpperCase().search(searchText) >= 0 
       // x.MobileNo.replace(/[^\w\s]/gi, ' ').toUpperCase().search(searchText) >= 0

      );
    }

  }
  //Images Saving part 

  selectPhoto(file: FileList) {
    this.picture = file.item(0).name;
    this.fileToUpload = file.item(0);
    const File = this.fileToUpload.name;
    this.user.FileName = File.split('.')[0];
    this.files = File.split('.');
    this.user.FileType = this.files[this.files.length - 1];
    this.noImage = false;

    // Show Image Preview
    const reader = new FileReader();
    reader.onload = (event: any) => {
      this.imgURL = event.target.result;
    };
    reader.readAsDataURL(this.fileToUpload);
  }
  pagesize($event) {
    this.isPageSize = false;
    setTimeout(() => {     
      this.pageSize = Number($event.target.value);
      this.isPageSize = true;
    }, 500);
   console.log( this.pageOfItems);
  }

}
