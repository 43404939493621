import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { NgForm, FormGroup, FormBuilder, Validators } from '@angular/forms';
import{Authentication}from './authentication';
import{LoginService}from './login.service';
import { ToasterService, ToasterConfig } from 'angular2-toaster';
import{User}from'../admin/users/User';
import { NgxUiLoaderService } from 'ngx-ui-loader'; // Import NgxUiLoaderService
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [ToasterService]
})

export class LoginComponent implements OnInit {
  isListView = true;
  model: any = {};
  errorMessage = '';
  loading = false;
  loginForm: FormGroup;
  forgetemail:FormGroup;
  user=new User();
  submitted = false;
  emailsubmitted=false;
  authentication=new Authentication();
  users=new User();
  private loginService: LoginService;
  private toasterService: ToasterService;
  public toasterconfig: ToasterConfig =
  new ToasterConfig({
  tapToDismiss: true,
  timeout: 3000
});
  constructor(private router: Router,private formBuilder: FormBuilder,_loginService: LoginService,toasterService: ToasterService,private ngxService: NgxUiLoaderService) {
    this.toasterService = toasterService;
    this.loginService = _loginService;
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      passWord: ['', Validators.required],
  });
  this.forgetemail = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    //passWord: ['', Validators.required],
});
let userData=JSON.parse(localStorage.getItem('UserDetails'));
  if(userData != null && userData != undefined){
    this.router.navigate(['/dashboard']);
  }
  else{
    this.router.navigate(['/login']);
  }
  }
  ChangeView() {
    this.isListView = this.isListView ? false : true;

  }
  // login() {
  //   console.log('click');
  //   window.location.href='/dashboard';
  // }

   // convenience getter for easy access to form fields
   get f() { return this.loginForm.controls; }
   get fe() { return this.forgetemail.controls; }
   login() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.loginForm.invalid) {
        return;
    }
else{
  this.ngxService.start();
  this.authentication.Email = this.loginForm.get('email').value;
  this.authentication.Password=this.loginForm.get('passWord').value;
  this.loginService.UserLogin(this.authentication)
      .subscribe((data: any) => {
        console.log(data);
        if (data.IsSuccess) {
          this.users = data.Result;
          localStorage.clear();
          localStorage.setItem('UserDetails', JSON.stringify(this.users));
          localStorage.setItem('token',data.Result.token);
          window.location.href='/dashboard';
          this.ngxService.stop();
          this.toasterService.pop('success', '', 'Login Successfully');
        }
        else{
          this.ngxService.stop();
          this.toasterService.pop('error', '', 'Invalid User');
        }

      }, (err: HttpErrorResponse) => {
        console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
        this.ngxService.stop();
      });
}
}
forgetpassword()
{
  this.user.Email='';
  this.ChangeView();
}
send()
{
let object={
   'Email':this.forgetemail.get('email').value,
}
this.emailsubmitted = true;
    // stop here if form is invalid
    if (this.forgetemail.invalid) {
        return;
    }
  
  else{ this.loginService.ForgetPassword(object)
  .subscribe((data: any) => {
    console.log(data);
    if (data.IsSuccess) {
      if(data.Result=='Success')
      {
      this.toasterService.pop('success', '', 'Email Sent Successfully');
      this.ChangeView();
      }
      
      if(data.Result=='Invalid User')
      {
        this.toasterService.pop('error', '', 'Invalid User.');
      }
    }
  }, (err: HttpErrorResponse) => {   
    console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
  });
}
}
Cancel()
{
  this.ChangeView();
}
// onKeydown(event) {
//   if (event.key === "Enter") {
//     this.login();
//   }
// }
}
