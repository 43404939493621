import { Component, OnInit, ViewChild } from '@angular/core';
import { ToasterService, ToasterConfig } from 'angular2-toaster';
import { HttpErrorResponse } from '@angular/common/http';
import { UserService } from './user.service';
import { User } from './User';
import { ClientsService } from '../clients/clients.service';
import {ModalDirective} from 'ngx-bootstrap/modal';
import { Role } from './role';
import { NgxUiLoaderService } from 'ngx-ui-loader'; // Import NgxUiLoaderService
import { parse } from 'querystring';
// declare let addeventatc: any;

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css'],
  providers: [ToasterService]
})
export class UsersComponent implements OnInit {
  @ViewChild('infoModal') public infoModal: ModalDirective;
  isListView = true;
  isPageSize = true;
  private toasterService: ToasterService;
  private userService: UserService;
  userList: Array<User>;
  user: User = new User();
  userRevert: User = new User();
  public toasterconfig: ToasterConfig =
    new ToasterConfig({
      tapToDismiss: true,
      timeout: 3000
    });
  
   roleList: Array<Role>;
  items: Array<any>;
  pageOfItems: Array<any>;
  customerList = [];
  pageSize = 10;
  picture: any;
  fileToUpload: File;
  files: any;
  imgURL: string = '';
  image: string;
  noImage: boolean = true;
  public message: string = "";
  RegenerateLink=false;
  RegeneratedLink: string="";
  ClientDashboardSessions: any;
  // addeventatc:any;
  AccountCreatedDate: any;
  ClientTimeZone: any;
  ClientState: any;
  ACEClientId:any;
  SessionAllowed:any;
  SessionUsed:any;
  RemainSession:any;
  ClientCaseId:any;
  constructor(
    _toasterService: ToasterService,
    _userService: UserService,
    private clientsService: ClientsService,
    private ngxService: NgxUiLoaderService

  ) {
    this.toasterService = _toasterService;
    this.userService = _userService;
  }

  ngOnInit() {
    this.GeUsers();
    this.BindCustomer();
  }
  // ngAfterViewInit(){    
  //   addeventatc.refresh();    
  // }
  BindCustomer() {
    this.clientsService.GetClients()
      .subscribe((data: any) => {
        console.log(data);
        if (data.IsSuccess) {
          this.customerList = data.Result;

        }
      }, (err: HttpErrorResponse) => {
        console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
      });
  }
 
  GeUsers() {
    this.ngxService.start();
    this.userService.GetClientUsers()
      .subscribe((data: any) => {
        console.log(data);
        if (data.IsSuccess) {
          this.userList = data.Result;
          this.items = this.userList;
        }
        this.ngxService.stop();
      }, (err: HttpErrorResponse) => {
        console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
        this.ngxService.stop();
      });
  }
  GeUser(Id, isDelete) {
    debugger
    this.ClientCaseId='';
    this.RegenerateLink=false;
    localStorage.setItem('SelectedUserId',Id)
    this.GetRoles();
    const currentUser = this.userList.find(x => x.UserId === Id);
    localStorage.setItem('SelectedUserDeviceId',currentUser.DeviceId);
    localStorage.setItem('SelectedUserLoginType', currentUser.LoginType);
    this.user = Object.assign({}, currentUser);
    this.AccountCreatedDate=this.user.CreatedOn;
    this.ClientTimeZone=this.user.TimezoneName;
    this.ClientState=this.user.State;
    this.ACEClientId=this.user.ACEClientId;
    this.SessionAllowed=this.user.AllowedAppts;
    this.SessionUsed=this.user.UsedAppts;
    this.RemainSession=this.user.RemainAppts;
    this.userService.GetClientDashboard(this.user.UserId, this.user.ACEClientId)
      .subscribe((response: any) => {
        console.log(response);
        if (response) {
          // this.ClientDashboardSessions = response.Result;
          this.ClientCaseId=response.Result.CaseId;
        }
        else {
        }
      }, (err: HttpErrorResponse) => {
        console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
        // setTimeout(function(){addeventatc.refresh();},500);
      });

    //this.clone();
    if (!isDelete) {

      this.ChangeView();
      //console.log(this.user);
    }
    this.BindCustomer();
  }
  ChangeView() {
    this.isListView = this.isListView ? false : true;

  }
  AddNew() {
    this.ChangeView();
  }
  showSuccess() {
    this.toasterService.pop('success', '', 'Data saved Successfully !');
  }
  showWarning() {
    this.toasterService.pop('warning', '', 'Data Reset Successfully');
  }
  Save() {
    if (this.imgURL != '')
      this.user.Base64Data = this.imgURL.split(',')[1];
    this.userService.PostUser(this.user)
      .subscribe((data: any) => {
        console.log(data);
        if (data.IsSuccess) {
          this.showSuccess();
          this.GeUsers();
          setTimeout(() => {
            this.ChangeView();
          }, 3000);

        }
      }, (err: HttpErrorResponse) => {
        this.message = err.error.Message;
        if (this.message.includes("Cannot insert duplicate key row in object 'dbo.Users' with unique index 'IX_Users_1'."))
          this.toasterService.pop('error', '', 'Email Is Already Exist');
        console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
      });
  }
  Reset() {
    // this.showWarning();
    // setTimeout(() => {
    //   //this.ChangeView();
    // }, 3000);
  }
  Back() {
    this.ChangeView();
  }
  onChangePage(pageOfItems: Array<any>) {
    console.log(pageOfItems);
    // update current page of items
    this.pageOfItems = pageOfItems;
  }
  FilterGrid(searchText) {
    console.log(searchText);
    if (searchText == '') {
      this.items = this.userList;
    } else {
      searchText = searchText.replace(/[^\w\s]/gi, ' ').toUpperCase().trim();

      this.items = this.userList.filter(x =>
        //  x.OrganisationName.replace(/[^\w\s]/gi, ' ').toUpperCase().search(searchText) >= 0
          ((x.RoleName?true:false) &&  (x.RoleName.replace(/[^\w\s]/gi, ' ').toUpperCase().search(searchText) >= 0))||
          ((x.FullName?true:false) &&  (x.FullName.replace(/[^\w\s]/gi, ' ').toUpperCase().search(searchText) >= 0))||
          ((x.OrganisationName?true:false) &&  (x.OrganisationName.replace(/[^\w\s]/gi, ' ').toUpperCase().search(searchText) >= 0))||
          ((x.Email?true:false) &&  (x.Email.replace(/[^\w\s]/gi, ' ').toUpperCase().search(searchText) >= 0))
  
  
        );
    }

  }
  Delete() {    
    this.user.IsActive = false;
    this.userService.DeleteUser(this.user)
      .subscribe((data: any) => {
        console.log(data);
        if (data.IsSuccess) {
          this.toasterService.pop('success', '', 'Record Deleted Successfully');
          this.GeUsers();
          this.infoModal.hide();
       
        }
      }, (err: HttpErrorResponse) => {
        console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
      });
  }
  GetRoles()
  {
    this.userService.GetRoles()
      .subscribe((data: any) => {
        console.log(data);
        if (data.IsSuccess) {
          this.roleList = data.Result;
        }
      }, (err: HttpErrorResponse) => {
        console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
      });
  }
  pagesize($event) {
    this.isPageSize = false;
    setTimeout(() => {     
      this.pageSize = Number($event.target.value);
      this.isPageSize = true;
    }, 500);
   console.log( this.pageOfItems);
  }

  GenerateActivationLink(){
    let Id=parseInt( localStorage.getItem('SelectedUserId'));
    let DeviceID=localStorage.getItem('SelectedUserDeviceId');
    let Login_type=localStorage.getItem('SelectedUserLoginType');
    this.RegenerateLink=true;
    this.userService.GetActivationLink(Id,DeviceID,Login_type)
      .subscribe((data: any) => {
        console.log(data);
        this.RegeneratedLink=data.Result;
      }, (err: HttpErrorResponse) => {
        console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
      });
  }
  CopyActivationLink(){
    let copytext=this.RegeneratedLink;
    window.navigator['clipboard'].writeText(copytext);
    this.toasterService.pop('success', '', 'Link Copied !');
  }
}
