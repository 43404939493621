import {HttpBackend, HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RefreshTokenHttpService {
  private httpClient: HttpClient;

  constructor(handler: HttpBackend,) {
    this.httpClient = new HttpClient(handler);
  }

  RefreshToken() {
    var currentUser = JSON.parse(localStorage.getItem('UserDetails'));
    var token = localStorage.getItem('token');
    this.httpClient.get<any>(`${environment.apiUrl}api/User/RefreshToken?UserId=`+currentUser.UserId+`&token=`+token+``).subscribe((response: any) => {
        if(response.IsSuccess){
            localStorage.setItem('token',response.Result) ;
        }      
    });
  }

}