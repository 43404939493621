import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import{CmsPage}from './cms-page';

@Injectable({
  providedIn: 'root'
})
export class CmsPageService {

  constructor(private http: HttpClient) { }
  GetCMSPages() {
    return this.http.get<any>(`${environment.apiUrl}api/CMSPage/GetCMSPages`);
  }
  AddUpdateCMSPage(cmspage: CmsPage) {
    return this.http.post<any>(`${environment.apiUrl}api/CMSPage/AddUpdateCMSPage`, cmspage);
  }
  GetCMSpageById(Id: number) {
    return this.http.get<any>(`${environment.apiUrl}api/CMSPage/GetCMSPageById?CMSPageId=` + Id);
  }
  DeletecmsPage(Id:number)
  {
    //return this.http.post<number>(`${environment.apiUrl}api/ContentArea/DeleteContentArea?ContentAreaId=` + Id);
    return this.http.get<any>(`${environment.apiUrl}api/CMSPage/DeleteCMSPage?CMSPageId=` + Id);
  }

}
