import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { User } from './User';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private http: HttpClient) { }

  GetUsers() {
    return this.http.get<any>(`${environment.apiUrl}api/User/GetUsers`);
  }
  GetUser(Id: number) {
    return this.http.get<any>(`${environment.apiUrl}api/User/GetUsers/${Id}`);
  }
  GetUsersByRole(Id: number) {
    return this.http.get<any>(`${environment.apiUrl}api/User/GetUsersByRole/${Id}`);
  }  
  GetClientUsers() {
    return this.http.get<any>(`${environment.apiUrl}api/User/GetClientUsers`);
  }  
  PostUser(user: User) {
    return this.http.post<any>(`${environment.apiUrl}api/User/PostUser`, user);
  }
  GetRoles() {
    return this.http.get<any>(`${environment.apiUrl}api/Role/GetRoles`);
  }
  GetCounsellor() {
    return this.http.get<any>(`${environment.apiUrl}api/User/GetCounsellor`);
  }
  GetOrganization()
  {
    return this.http.get<any>(`${environment.apiUrl}api/ClientOrganisation/GetClientOrganisation`);
  }
  GetUserById(UserId: number)
  {  return this.http.get<any>(`${environment.apiUrl}api/User/GetUserById?UserId=`+UserId);
    //return this.http.get<any>(`${environment.apiUrl}api/User/GetUserById/${UserId}`);
  }
  SendCredential(user: User) {
    return this.http.post<any>(`${environment.apiUrl}api/User/SendCredential`, user);
  }
  DeleteUser(user: User) {
    return this.http.post<any>(`${environment.apiUrl}api/User/DeleteUser`, user);
  }
  AddUpdateAdminUser(user: User) {
    return this.http.post<any>(`${environment.apiUrl}api/User/AddUpdateAdminUser`, user);
  }
  GetActivationLink(Id: number,DeviceID: string, Login_type: string) {
    return this.http.get<any>(`${environment.apiUrl}api/User/GetActivationLink?UserID=`+Id+'&Deviceid='+DeviceID + '&Login_type='+Login_type);
  }

  GetClientDashboard(UserId : any,clientid:any) {
    return this.http.get<any>(`${environment.apiUrl}api/Ace/GetClientDashboardDetails?UserId=`+UserId+`&clientId=` + clientid +``);
  }
  GetServerMaintenance()
  {
    return this.http.get<any>(`${environment.apiUrl}api/ClientOrganisation/GetServermaintenance`);
  }
  ServerMaintenanceUpdate(ACEClientId : any,Servermaintenance:any,Notes:any) {
    return this.http.get<any>(`${environment.apiUrl}api/ClientOrganisation/GetServermaintenanceupdate?ACEClientID=`+ACEClientId+`&Servermaintenance=` + Servermaintenance +`&Notes=` + Notes +``);
  }
}
