export class ContentModuleVedio {
    ContentVideoId:number;
    ContentModuleId:number;
    VideoTitle:string;
    VideoFile:string;
    IsActive:boolean;
    CreatedOn:Date;
    CreatedBy:number;
    UpdatedOn:Date;
    UpdatedBy:number;

    Base64Data: any;
    FileName: any;
    FileType: any;
}
