import { Component, OnInit, ViewChild } from '@angular/core';
import { ToasterService, ToasterConfig } from 'angular2-toaster';
import { UserService } from '../users/user.service';
import { User } from '../users/User';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import{Clients} from '../clients/clients';
import { NgxUiLoaderService } from 'ngx-ui-loader'; // Import NgxUiLoaderService
import { ModalDirective } from 'ngx-bootstrap';

@Component({
  selector: 'app-manage-counsellers',
  templateUrl: './manage-counsellers.component.html',
  styleUrls: ['./manage-counsellers.component.css'],
  providers: [ToasterService]
})
export class ManageCounsellersComponent implements OnInit {
  @ViewChild('infoModal') public infoModal: ModalDirective;
  // Datepicker

 // minDate = new Date(1970, 5, 10);
  //maxDate = new Date(2018, 9, 15);

  bsValue: Date = new Date();
  bsRangeValue: any = [new Date(2017, 7, 4), new Date(2017, 7, 20)];

  items: Array<any>;
  isPageSize = true;
  pageOfItems: Array<any>;  
  pageSize=10;
  isListView = true;
  user=new User();
  userRevert: User = new User();
  userList: Array<User>;
  clientList: Array<Clients>;
  private userService: UserService;
  private router: Router;
  private toasterService: ToasterService;
  public toasterconfig: ToasterConfig =
  new ToasterConfig({
    tapToDismiss: true,
    timeout: 3000
  });
constructor(toasterService: ToasterService,_userService: UserService, _router: Router,private ngxService: NgxUiLoaderService) {
  this.toasterService = toasterService;
  this.userService=_userService;
  this.router=_router;
}

ngOnInit() {
  this.GetCounsellor();
}
ChangeView() {
  this.isListView = this.isListView ? false : true;

}
AddNew() {
  const currentUser = new User();
  this.user = Object.assign({}, currentUser);
  this.user.IsActive=true;
  this.user.OrganisationId=0;
  this.GetOrganization();
  this.ChangeView();
  
}
showSuccess() {
  this.toasterService.pop('success', '', 'Data saved Successfully !');
}
showWarning() {
  this.toasterService.pop('warning', '', 'Data Reset Successfully');
}
// Save() {
//   this.showSuccess();
//   setTimeout(() => {
//     this.ChangeView();
//   }, 3000);   

// }
onChangePage(pageOfItems: Array<any>) {
  console.log(pageOfItems);
  // update current page of items
  this.pageOfItems = pageOfItems;
}
GetCounsellor()
{
  this.ngxService.start();
  this.userService.GetCounsellor()
  .subscribe((data: any) => {
    console.log(data);
    if (data.IsSuccess) {
      this.userList = data.Result;
      this.items = this.userList;
    }
    this.ngxService.stop();
  }, (err: HttpErrorResponse) => {
    console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
    this.ngxService.stop();
  });
}
FilterGrid(searchText) {
  console.log(searchText);
  if (searchText == '') {
    this.items = this.userList;
  } else {
    searchText = searchText.replace(/[^\w\s]/gi, ' ').toUpperCase().trim();
    this.items = this.userList.filter(x =>
      //  x.OrganisationName.replace(/[^\w\s]/gi, ' ').toUpperCase().search(searchText) >= 0
       
        ((x.FullName?true:false) &&  (x.FullName.replace(/[^\w\s]/gi, ' ').toUpperCase().search(searchText) >= 0))||
        ((x.OrganisationName?true:false) &&  (x.OrganisationName.replace(/[^\w\s]/gi, ' ').toUpperCase().search(searchText) >= 0))||
        ((x.Email?true:false) &&  (x.Email.replace(/[^\w\s]/gi, ' ').toUpperCase().search(searchText) >= 0))
    )}

}
pagesize($event) {
  this.isPageSize = false;
  setTimeout(() => {     
    this.pageSize = Number($event.target.value);
    this.isPageSize = true;
  }, 500);
 console.log( this.pageOfItems);
}
GetOrganization()
{
  this.userService.GetOrganization()
  .subscribe((data: any) => {
    console.log(data);
    if (data.IsSuccess) {
      this.clientList=data.Result;
    }
  }, (err: HttpErrorResponse) => {
    console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
  });
}
Edit(UserId) {
  this.GetUserById(UserId);
  console.log(this.user);
  const currentUser = new User();
  this.user = Object.assign({}, currentUser);
  this.ChangeView();
  this.GetOrganization();
}
GetUserById(UserId)
{
  this.userService.GetUserById(UserId)
  .subscribe((data: any) => {
    if (data.IsSuccess) {
      this.user = data.Result;
      this.clone();
    }
  }, (err: HttpErrorResponse) => {
    console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
  });
}
clone() {
  this.userRevert = Object.assign({}, this.user);
}
Reset() {
  const itemObj = Object.assign({}, this.userRevert);
  this.user = itemObj;
  if(this.user.OrganisationId==undefined)
  this.user.OrganisationId=0;
  this.user.IsActive=true;
}
Back() {
  this.ChangeView();
}
Save() {
  this.user.RoleId=5;
  //this.user.UserName=this.user.FirstName;
  this.user.DateOfBirth.toDateString();
  this.user.FullName=this.user.FirstName+' '+this.user.LastName;
  this.user.WorkEmail=this.user.Email;
  this.userService.PostUser(this.user)
    .subscribe((data: any) => {
      console.log(data);
      if (data.IsSuccess) {
       
          this.toasterService.pop('success', '', 'FAQ Added Successfully');
          this.ChangeView();
          this.GetCounsellor();
       
      }
    }, (err: HttpErrorResponse) => {
      console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
    });
}
SendCredential()
{
  //this.user.UserName=this.user.FirstName;
  this.user.FullName=this.user.FirstName+' '+this.user.LastName;
  this.user.WorkEmail=this.user.Email;
  this.userService.SendCredential(this.user)
    .subscribe((data: any) => {
      console.log(data);
      if (data.IsSuccess) {
       
          this.toasterService.pop('success', '', 'Email Sent Successfully');
          this.ChangeView();
          this.GetCounsellor();
       
      }
    }, (err: HttpErrorResponse) => {
      console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
    });
}

Delete() {   
  debugger;
  this.user.IsActive = false;
  this.userService.DeleteUser(this.user)
  .subscribe((data: any) => {
    console.log(data);
    if (data.IsSuccess) {
      this.toasterService.pop('success', '', 'Record Deleted Successfully');
      this.GetCounsellor();
      this.infoModal.hide();
   
    }
  }, (err: HttpErrorResponse) => {
    console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
  });
}
GeUser(Id,isDelete) {
  debugger;
  const currentUser = this.userList.find(x => x.UserId === Id);
  this.user = Object.assign({}, currentUser);

  this.clone();
  if(!isDelete)
  {
  this.ChangeView();
  //console.log(this.user);
}
}
}
