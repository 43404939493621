import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import{Faq}from './faq';

@Injectable({
  providedIn: 'root'
})
export class FaqService {

  constructor(private http: HttpClient) { }
  GetFAQs() {
    return this.http.get<any>(`${environment.apiUrl}api/FAQ/GetFAQ`);
  }
  AddUpdatefaq(faq: Faq) {
    return this.http.post<any>(`${environment.apiUrl}api/FAQ/AddUpdateFAQs`, faq);
  }
  GetFAQById(Id: number) {
    return this.http.get<any>(`${environment.apiUrl}api/FAQ/GetFAQById?FaqId=` + Id);
  }
  
  DeleteFaq(Id:number)
  {
    //return this.http.post<number>(`${environment.apiUrl}api/ContentArea/DeleteContentArea?ContentAreaId=` + Id);
    return this.http.get<any>(`${environment.apiUrl}api/FAQ/DeleteFAQ?FaqId=` + Id);
  }
}
