export class ChatTemplate{
    ChatTemplatesID_PK:number;
    MessageTemplate:string;
    CreatedUserID:number;
    CreatedDateTime:Date;
    UpdatedUserID:number;
    UpdatedDateTime:Date;
    constructor(){
        this.ChatTemplatesID_PK=0;
        this.MessageTemplate=null;
        this.CreatedUserID=null;
        this.CreatedDateTime=null;
        this.UpdatedUserID=null;
        this.UpdatedDateTime=null;
    }
}