import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
 import{EmailTemplate}from './email-template';

@Injectable({
  providedIn: 'root'
})
export class EmailTemplateService {

  constructor(private http: HttpClient) { }
  GetTemplate() {
    return this.http.get<any>(`${environment.apiUrl}api/User/GetEmailTemplate`);
  }
  AddUpdateEmailTemplate(cmspage: EmailTemplate) {
    return this.http.post<any>(`${environment.apiUrl}api/User/AddUpdateEmailTemplate`, cmspage);
  }
  GetEmailTemplateById(Id: number) {
    return this.http.get<any>(`${environment.apiUrl}api/User/GetEmailTemplateById?EmailTemplateId=` + Id);
  }
}
