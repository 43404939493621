export class ContentTopics {
    RowId:number;
    ContentTopicId:number; 
    TopicName:string;
    Summary:string;
    Summarywithsplit:string;
    ContentAreaName:string;
    ContentAreaId:number;
    IconImage:string;
    NoOfModules:number;
    IsActive:boolean;
    CreatedOn:Date;
    CreatedBy:number;
    UpdatedOn:Date;
    UpdatedBy:number;

    Base64Data: any;
    FileName: any;
    FileType: any;
}
