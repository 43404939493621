import { Component, OnInit, ViewChild } from '@angular/core';
import { ToasterService, ToasterConfig } from 'angular2-toaster';
import { ContentModulesService } from '../../content-modules/content-modules.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ContentModules } from '../../content-modules/content-modules';
import { ContentAreas } from '../../content-areas/content-areas';
import { ContentTopics } from '../../content-topics/content-topics';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Router, ActivatedRoute } from '@angular/router';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { NgxUiLoaderService } from 'ngx-ui-loader'; // Import NgxUiLoaderService
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-modules',
  templateUrl: './modules.component.html',
  styleUrls: ['./modules.component.css'],
  providers: [ToasterService]
})
export class ModulesComponent implements OnInit {
  @ViewChild('infoModal') public infoModal: ModalDirective;
  fixVedioSize: number = 200;
  fixAudioSize: number = 150;
  aspectRatio: number = 1.78;
  fixImgSize: number = 800;
  imgSize: boolean = false;
  imgType: boolean = false;
  imgAspectRatio: boolean = false;
  vedioSize: boolean = false;
  audioSize: boolean = false;
  Deletevideofile: boolean = false;
  DeleteAudiofile: boolean = false;
  vedioType: boolean = false;
  vedioTypeMsg: boolean = false;
  audioType: boolean = false;
  audioTypemsg: boolean = false;
  vedioAspectRatio: boolean = false;
  orderedlist: any[] = [];
  isRequired = true;
  splitChar: number = 100;
  summaryArraysplit: string;
  imgRevert: string = '';
  vidRevert: string = '';
  audRevert: string = '';
  isVisible: boolean = false;
  Videodisabled: boolean = false;
  Audiodisabled: boolean = false;
  picture: any;
  fileToUpload: File;
  files: any;
  imgURL: string = '';
  URL: string = '';
  image: string;
  adutiourl: string = '';
  audSrc:SafeUrl;
  vedio: string;
  audio: string;
  noImage: boolean = true;
  noVedio: boolean = true;
  noaudio: boolean = true;
  progresskk:any;
  progresskkaudio:any;
  isListView = true;
  Id: number;
  contentModuleVideoList = [];
  contentAreaId: number;
  contentModuleRevert: ContentModules = new ContentModules();
  contentModule = new ContentModules();
  contentModuleList: Array<ContentModules>;
  contentAreaList: Array<ContentAreas>;
  contentTopicList: Array<ContentTopics>;
  items: Array<any>;
  pageOfItems: Array<any>;
  pageSize = 10;
  isPageSize = true;
  private toasterService: ToasterService;
  private contentModuleService: ContentModulesService;
  private router: Router;
  private route: ActivatedRoute;
  public ContentAreaId: number;
  public ContentTopicId: number;
  public AreaName: string;
  public TopicName: string;
  public message: string = "";
  ckeConfig: any;
  public toasterconfig: ToasterConfig =
    new ToasterConfig({
      tapToDismiss: true,
      timeout: 3000
    });
  constructor(toasterService: ToasterService, _contentModuleService: ContentModulesService, _router: Router, _route: ActivatedRoute, private ngxService: NgxUiLoaderService,private sanitizer: DomSanitizer) {
    this.toasterService = toasterService;
    this.router = _router;
    this.route = _route;
    this.contentModuleService = _contentModuleService;
    // this.ContentAreaId =parseInt(this.route.snapshot.paramMap.get("id1"));
    // this.ContentTopicId= parseInt(this.route.snapshot.paramMap.get("id2"));

  }

  ngOnInit() {
    this.GetContentModule();
    //this.GetContentTopics(this.ContentAreaId);
    this.ckeConfig = {
      allowedContent: false,
      extraPlugins: 'divarea',
      forcePasteAsPlainText: true,
      contentsCss: ["body {font-family: 'Helvetica Neue';}"],

    };
  }
  ChangeView() {
    this.isListView = this.isListView ? false : true;

  }
  AddNew() {
    this.isRequired = true;
    this.isVisible = false;
    this.GetContentAreas();
    // this.imgURL="";
    this.noImage = true;
    this.contentModule = new ContentModules();
    this.contentModule.ContentAreaId = undefined;
    this.contentModule.ContentTopicId = undefined;
    //this.GetContentTopics(this.ContentAreaId);
    this.clone();
    this.ChangeView();

  }
  showSuccess() {
    this.toasterService.pop('success', '', 'Data saved Successfully !');
  }
  showWarning() {
    this.toasterService.pop('warning', '', 'Data Reset Successfully');
  }
  Save() {
    debugger;
    this.ngxService.start();
    if (this.imgType || this.imgSize || this.imgAspectRatio) {
      this.ngxService.stop();
      return false;

    }
    if (this.vedioType || this.vedioSize || this.vedioAspectRatio) {
      this.ngxService.stop();
      return false;

    }
    if (this.audioType|| this.audioSize) {
      this.ngxService.stop();
      return false;
    }
    if(this.Deletevideofile){
      this.contentModule.VideoFile=null;
    }
    if(this.DeleteAudiofile){
      this.contentModule.AudioFile="";
    }
    if (this.URL != '')
      this.contentModule.Base64DataForVedio = this.URL.split(',')[1];
    if (this.imgURL != '')
      this.contentModule.Base64Data = this.imgURL.split(',')[1];
      if (this.adutiourl != ''){}
      this.contentModule.Base64DataForAudio = this.adutiourl.split(',')[1];
      if(this.Deletevideofile){
        this.contentModule.VideoFile=null;
        this.contentModule.Base64DataForVedio=null;
      }
      if(this.DeleteAudiofile){
        this.contentModule.AudioFile="";
        this.contentModule.Base64DataForAudio=null;
      }
    this.contentModuleService.AddUpdateContentModule(this.contentModule)
      .subscribe((data: any) => {
        if (data.IsSuccess) {
          this.toasterService.pop('success', '', 'ContentModule Added Successfully');
          this.ChangeView();
          this.GetContentModule();
          this.ngxService.stop();
         this.Videodisabled = false;
        this.Audiodisabled = false;
        }
      }, (err: HttpErrorResponse) => {
        this.message = err.error.Message;
        if (this.message.includes("Cannot insert duplicate key row in object 'dbo.ContentModules' with unique index 'IX_ContentModules'."))
          this.toasterService.pop('error', '', 'ModuleName Is Already Exist');
        console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
      });
  }
  clone() {
    this.contentModuleRevert = Object.assign({}, this.contentModule);
  }
  Reset() {
    const itemObj = Object.assign({}, this.contentModuleRevert);
    this.contentModule = itemObj;
    if (this.contentModule.IconImage == null)
      this.noImage = true;
    if (this.contentModule.IconImage != null)
      this.imgURL = this.imgRevert;
    if (this.contentModule.VideoFile == null)
      this.noVedio = true;
      if (this.contentModule.AudioFile == null)
      this.noaudio = true;
    if (this.contentModule.VideoFile != null)
      this.URL = this.vidRevert;
      if (this.contentModule.AudioFile != null)
      this.audSrc = this.audRevert;
    this.imgSize = false;
    this.imgType = false;
    this.imgAspectRatio = false;
    this.vedioSize = false;
    this.audioSize=false;
    this.vedioType = false;
    this.vedioTypeMsg=false;
    this.audioType=false;
    this.audioTypemsg=false;
    this.vedioAspectRatio = false;
    this.Videodisabled = false;
    this.Audiodisabled = false;
  }
  GetContentModule() {
    this.ngxService.start();
    this.contentModuleService.GetContentModules()
      .subscribe((data: any) => {
        if (data.IsSuccess) {
          this.contentModuleList = data.Result;
          this.GetSummary(this.contentModuleList);
          this.items = this.contentModuleList;
        }
        this.ngxService.stop();
      }, (err: HttpErrorResponse) => {
        console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
        this.ngxService.stop();
      });
  }

  GetContentAreas() {
    this.contentModuleService.GetContentAreas()
      .subscribe((data: any) => {
        if (data.IsSuccess) {
          this.contentAreaList = data.Result;
          this.items = this.contentAreaList;
        }
      }, (err: HttpErrorResponse) => {
        console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
      });
  }
  GetContentTopics(contentAreaId) {
    this.contentModuleService.GetContentTopics(contentAreaId)
      .subscribe((data: any) => {
        if (data.IsSuccess) {
          this.contentTopicList = data.Result;
          let index = this.contentTopicList.findIndex(c => c.ContentTopicId == this.ContentTopicId);
          let topic = this.contentTopicList[index];
          this.AreaName = topic.ContentAreaName;
          this.TopicName = topic.TopicName;
        }
      }, (err: HttpErrorResponse) => {
        console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
      });
  }
  areaChange($event) {
    this.contentAreaId = $event.target.value;
    this.GetContentTopics(this.contentAreaId);
    this.contentModule.ContentTopicId = undefined;
  }
  Back() {
    this.ChangeView();
    this.GetContentModule();
    this.imgSize = false;
    this.imgType = false;
    this.imgAspectRatio = false;
    this.noImage = true;
    this.vedioSize = false;
    this.audioSize=false;
    this.vedioType = false;
    this.vedioTypeMsg=false;
    this.audioType = false;
    this.audioTypemsg=false;
    this.vedioAspectRatio = false;
    this.noVedio = true;
    this.noaudio = true;
    this.Videodisabled = false;
    this.Audiodisabled = false;
  }

  selectPhoto(file: FileList) {
    this.picture = file.item(0).name;
    this.fileToUpload = file.item(0);
    const File = this.fileToUpload.name;
    //this.contentModule.FileName = File.split('.')[0];
    this.files = File.split('.');
    this.contentModule.FileType = this.files[this.files.length - 1];
    var regex = new RegExp("(jpg|png|gif|jpeg)$");
    if (regex.test(this.contentModule.FileType.toLowerCase())) {
      this.imgType = false;
    }
    else {
      this.imgType = true;
    }
    this.noImage = false;
    var totalsize = file.item(0).size;
    var totalSizeKB = totalsize / Math.pow(1024, 1);
    if (totalSizeKB < this.fixImgSize) {
      this.imgSize = false;
    }
    else {
      this.imgSize = true;
    }
    // Show Image Preview
    const reader = new FileReader();
    reader.onload = (event: any) => {
      //this.url=(<FileReader>event.target).result;
      this.imgURL = event.target.result;
      var image = new Image();
      image.src = event.target.result;
      image.onload = () => {
        var inputHeight = image.height;
        var inputWidth = image.width;
        var ratio = inputWidth / inputHeight;
        var n = parseFloat(ratio.toFixed(2));
        let outputWidth = inputWidth;
        let outputHeight = inputHeight;
        // if (n > this.aspectRatio) {
        //     outputWidth = inputHeight * this.aspectRatio;
        // } else if (n < this.aspectRatio) {
        outputHeight = inputWidth / this.aspectRatio;
        // }
        let marginHeightMax = outputHeight + (outputHeight * (10 / 100));
        let marginHeightMin = outputHeight - (outputHeight * (10 / 100));


        if (marginHeightMin < inputHeight && marginHeightMax > inputHeight) {
          this.imgAspectRatio = false;
        }
        else
          this.imgAspectRatio = true;
      };
    };
    reader.readAsDataURL(this.fileToUpload);

  }

  _handleReaderLoaded(readerEvt) {
    const binaryString = readerEvt.target.result;
    this.contentModule.Base64Data = btoa(binaryString);
  }
  // onChangePage(pageOfItems: Array<any>) {
  //   console.log(pageOfItems);

  //   this.pageOfItems = pageOfItems;
  // }
  FilterGrid(searchText) {
    console.log(searchText);
    if (searchText == '') {
      this.contentModuleList = this.items;
    } else {
      searchText = searchText.replace(/[^\w\s]/gi, ' ').toUpperCase().trim();
      this.contentModuleList = this.items.filter(x =>
        ((x.ModuleTitle ? true : false) && (x.ModuleTitle.replace(/[^\w\s]/gi, ' ').toUpperCase().search(searchText) >= 0)) ||
        ((x.ContentTopicName ? true : false) && (x.ContentTopicName.replace(/[^\w\s]/gi, ' ').toUpperCase().search(searchText) >= 0)) ||
        ((x.ContentAreaName ? true : false) && (x.ContentAreaName.replace(/[^\w\s]/gi, ' ').toUpperCase().search(searchText) >= 0)) ||
        ((x.Keywords ? true : false) && (x.Keywords.replace(/[^\w\s]/gi, ' ').toUpperCase().search(searchText) >= 0))
        //  ||
        // x.FullName.replace(/[^\w\s]/gi, ' ').toUpperCase().search(searchText) >= 0 ||
        // x.WorkEmail.replace(/[^\w\s]/gi, ' ').toUpperCase().search(searchText) >= 0
        // x.MobileNo.replace(/[^\w\s]/gi, ' ').toUpperCase().search(searchText) >= 0

      );
    }

  }

  DeletePopUp(ContentModuleId) {
    this.Id = ContentModuleId;
    this.infoModal.show();

  }
  Delete(ContentModuleId) {
    this.infoModal.hide();
    this.contentModuleService.DeleteContentModule(ContentModuleId)
      .subscribe((data: any) => {
        console.log(data);
        if (data.IsSuccess) {
          this.GetContentModule();
          this.toasterService.pop('success', '', 'Record Deleted Successfully');

        }
      }, (err: HttpErrorResponse) => {
        console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
      });
  }
  Edit(ContentAreaId, ContentTopicId, ContentModuleId) {
    this.isRequired = false;
    this.isVisible = true;
    this.GetContentModuleById(ContentAreaId, ContentTopicId, ContentModuleId);
    this.GetContentAreas();
    const currentContentModule = new ContentModules();
    this.contentModule = Object.assign({}, currentContentModule);
    this.contentModule.ContentAreaId = this.ContentAreaId;

    //  this.GetContentTopics(this.contentModule.ContentAreaId);
    this.ChangeView();


  }
  GetContentModuleById(ContentAreaId, ContentTopicId, ContentModuleId) {
    this.contentModuleService.GetContentModuleById(ContentModuleId, ContentAreaId, ContentTopicId)
      .subscribe((data: any) => {
        if (data.IsSuccess) {
          debugger;
          this.contentModule = data.Result;
          if (data.Result.IconImage != null) {
            this.imgURL = data.Result.IconImage;
            this.imgRevert = data.Result.IconImage;
            this.image = data.Result.IconImage.split('/');
            this.image = this.image[this.image.length - 1];
            this.contentModule.IconImage = this.image;
            this.noImage = false;
          } else {
            this.noImage = true;
          }
          if (data.Result.VideoFile != null && data.Result.VideoFile != "") {
            this.URL = data.Result.VideoFile;
            this.vidRevert = data.Result.VideoFile;
            this.vedio = data.Result.VideoFile.split('/');
            this.vedio = this.vedio[this.vedio.length - 1];
            this.contentModule.VideoFile = this.vedio;
            this.noVedio = false;
          } else {
            this.noVedio = true;
          }
          if (data.Result.AudioFile != null && data.Result.AudioFile != "") {
            this.audSrc = data.Result.AudioFile;
            this.adutiourl=data.Result.AudioFile;
            this.audRevert = data.Result.AudioFile;
            this.audio = data.Result.AudioFile.split('/');
            this.audio = this.audio[this.audio.length - 1];
            this.contentModule.AudioFile = this.audio;
            this.noaudio = false;
          } else {
            this.noaudio = true;
          }
          if(data.Result.videoVisibleTo==true)
          {
            this.Audiodisabled=true;
          }
          if(data.Result.AudioVisibleTo==true){
            this.Videodisabled=true;
          }
          this.clone();

          this.GetContentTopics(this.contentModule.ContentAreaId);
        }
      }, (err: HttpErrorResponse) => {
        console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
      });

  }
  GetSummary(data) {
    var i: number = 0;
    for (i; i < data.length; i++) {
      this.summaryArraysplit = null;
      if (data[i].Summary != null) {
        if (data[i].Summary.length > this.splitChar) {
          this.summaryArraysplit = data[i].Summary.substr(0, this.splitChar) + '...';
        }
      }
      this.contentModuleList[i].Summarywithsplit = this.summaryArraysplit;
    }
    console.log(this.contentModuleList);
  }
  // public view(ContentModuleId) {
  //   debugger;
  //   this.router.navigate([`content-module-vedio/` + this.ContentAreaId+ '/' + this.ContentTopicId +'/' + ContentModuleId  + ``]);
  // }
  pagesize($event) {
    this.isPageSize = false;
    setTimeout(() => {
      this.pageSize = Number($event.target.value);
      this.isPageSize = true;
    }, 500);
    console.log(this.pageOfItems);
  }
  selectVedio(file: FileList) {
    debugger;
    this.Deletevideofile=false;
   // this.ngxService.start();
    this.picture = file.item(0).name;
    this.fileToUpload = file.item(0);
    const File = this.fileToUpload.name;
    //this.contentModuleVedio.FileName = File.split('.')[0];
    this.files = File.split('.');
    this.contentModule.VedioType = this.files[this.files.length - 1];
    this.noVedio = false;
    var totalsize = file.item(0).size;
    var totalSizeMB = totalsize / Math.pow(1024, 2);
    if (totalSizeMB < this.fixVedioSize) {
      this.vedioSize = false;
      this.vedioTypeMsg=false;
    }
    else {
      this.vedioSize = true;
      this.vedioTypeMsg=true;
    }
    // Show Image Preview
    const reader = new FileReader();
    reader.onload = (event: any) => {
      this.URL = event.target.result;
      //this.getVideoDimensionsOf(this.URL);
      let video = document.createElement('video');

      // place a listener on it
      video.addEventListener("loadedmetadata", () => {
        // retrieve dimensions
        let inputHeight = video.videoHeight;
        let inputWidth = video.videoWidth;
        let ratio = inputWidth / inputHeight;
        var n = parseFloat(ratio.toFixed(2));

        let outputWidth = inputWidth;
        let outputHeight = inputHeight;
        // if (n > this.aspectRatio) {
        //     outputWidth = inputHeight * this.aspectRatio;
        // } else if (n < this.aspectRatio) {
        outputHeight = inputWidth / this.aspectRatio;
        //}
        let marginHeightMax = outputHeight + (outputHeight * (10 / 100));
        let marginHeightMin = outputHeight - (outputHeight * (10 / 100));


        if (marginHeightMin < inputHeight && marginHeightMax > inputHeight) {
          this.vedioAspectRatio = false;
          this.vedioTypeMsg=false;
        }
        else
        {
          this.vedioAspectRatio = true;
          this.vedioTypeMsg=true;
        }
      });

      // start download meta-datas
     video.src = this.URL;

    };
    reader.readAsDataURL(this.fileToUpload);
    reader.onprogress= (data: any) => {
       this.progresskk = Number((data.loaded / data.total) * 100);
    }

    var regex = new RegExp("(mp3|mp4)$");
    if (regex.test(this.contentModule.VedioType.toLowerCase())) {
      this.vedioType = false;
      this.vedioTypeMsg=false;
    }
    else {
      this.vedioType = true;
      this.vedioTypeMsg=true;
    }

    // setTimeout(() => {
    //   this.ngxService.stop();
    // }, 5000);
  }

  _handleReaderVedioLoaded(readerEvt) {
    const binaryString = readerEvt.target.result;
    this.contentModule.Base64DataForVedio = btoa(binaryString);
  }
  audFileSelected(file: FileList) {
    debugger;
    this.DeleteAudiofile=false;
    this.noaudio = true;
    this.progresskkaudio=0;
   // this.ngxService.start();
    this.picture = file.item(0).name;
    this.fileToUpload = file.item(0);
    const File = this.fileToUpload.name;
    this.files = File.split('.');
    this.contentModule.AudioType = this.files[this.files.length - 1];
    setTimeout(() => {
    this.noaudio = false
  }, 5000);
    var totalsize = file.item(0).size;
    var totalSizeMB = totalsize / Math.pow(1024, 2);
    if (totalSizeMB < this.fixAudioSize) {
      this.audioSize = false;
      this.audioTypemsg=false;
    }
    else {
      this.audioSize = true;
      this.audioTypemsg=true;
      this.noaudio = false
      return false;
    }
    const reader = new FileReader();
    if (this.fileToUpload.name && this.fileToUpload) {
      reader.onload = (evt: any) => {

        this.audSrc = this.sanitizeUrl(evt.target.result);
        this.adutiourl=evt.target.result

        let audio = document.createElement('audio');
        audio.addEventListener("loadedmetadata", () => {

        });
      // audio.src = evt.target.result;
          };
      }
      reader.readAsDataURL(this.fileToUpload);
      reader.onprogress= (data: any) => {
        this.progresskkaudio = Number((data.loaded / data.total) * 100);
     }
      var regex = new RegExp("(mp3|wav)$");
      if (regex.test(this.contentModule.AudioType.toLowerCase())) {
        this.audioType = false;
        this.audioTypemsg=false;
      }
      else {
        this.audioType = true;
        this.audioTypemsg=true;
      }
      // setTimeout(() => {
      //   this.ngxService.stop();
      // }, 5000);
  }
  sanitizeUrl(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.contentModuleList, event.previousIndex, event.currentIndex);
    this.orderedlist = [];
    console.log(this.contentModuleList);
    let count: number = 1;
    let object: any;
    this.contentModuleList.forEach(q => {
      object = {
        "ContentModuleId": q.ContentModuleId,
        "DisplayOrder": count++
      }
      this.orderedlist.push(object);
    });
    let obj = {
      'content': this.orderedlist
    }

    this.contentModuleService.UpdateorderBy(obj)
      .subscribe((data: any) => {
        console.log(data);
        if (data.IsSuccess) {
          this.GetContentModule();

        }
      }, (err: HttpErrorResponse) => {
        console.log(`'Problem with the sevice. Please try later :  ${err.message} `);
      });
  }
  EventCheckVideo(event){
    debugger;
    if(event.currentTarget.checked==true)
    {
    this.Audiodisabled=true;
    }
    else{
      this.Audiodisabled=false;
    }

  }
  EventCheckAudio(event){
    debugger;
    if(event.currentTarget.checked==true)
    {
    this.Videodisabled=true;
    }
    else{
      this.Videodisabled=false;
    }

  }
  Deletevideo(){
 this.noVedio=true;
 this.progresskk=0;
 this.Deletevideofile=true;
  }
  DeleteAudio(){
    this.DeleteAudiofile=true;
    this.noaudio=true;
    this.progresskkaudio=0;
     }

}
