import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import{ContentModuleVedio}from './content-module-vedio';


@Injectable({
  providedIn: 'root'
})
export class ContentModuleVedioService {

  constructor(private http: HttpClient) { }
  GetContentModuleVedio(Id:number) {
    return this.http.get<any>(`${environment.apiUrl}api/ContentModuleVedio/GetContentModuleVedio?ContentModuleId=` + Id);
  }
  AddUpdatecontentModuleVedio(contentModuleVedio: ContentModuleVedio) {
    return this.http.post<any>(`${environment.apiUrl}api/ContentModuleVedio/AddUpdateContentVedio`, contentModuleVedio);
  }
  GetContentModuleVedioById(Id: number,Id1:number) {
    return this.http.get<any>(`${environment.apiUrl}api/ContentModuleVedio/GetContentModuleVedioById?ContentVideoId=` + Id+'&ContentModuleId='+Id1);
  }
}
