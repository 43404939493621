import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Home } from './home';
import {Dashboardnotes}from './dashboardnotes';
import {Htmlconverter} from './htmlconverter';
import { Observable } from 'rxjs/internal/Observable';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(private http: HttpClient) { }
  GetDashBoardDetails(home: Home) {
    return this.http.post<any>(`${environment.apiUrl}api/DashBoard/GetDashBoardDetails`, home);
  }
  GetOrganizations() {
    return this.http.get<any>(`${environment.apiUrl}api/ClientOrganisation/GetClientOrganisation`);
  }
  AddDashbordNotes(dashboardnotes: Dashboardnotes) {
    return this.http.post<any>(`${environment.apiUrl}api/DashBoard/AddDashbordNotes`, dashboardnotes);
  }
  GetDashboardNote(dashboardnotes: Dashboardnotes) {
    return this.http.post<any>(`${environment.apiUrl}api/DashBoard/GetDashboardNote`, dashboardnotes);
  }
  Getpdf(htmlconvert:Htmlconverter):Observable<Blob>{
    return this.http.post(`${environment.apiUrl}api/FAQ/DownloadPdf`,htmlconvert,{responseType:'blob'});
  }
  public downloadFile(htmlconvert) {
    this.http.get(`${environment.apiUrl}api/FAQ/DownloadPdf/`+htmlconvert, { responseType: 'blob' }).subscribe(blob => {
       saveAs(blob, 'text.pdf', {
          type: 'application/pdf' // --> or whatever you need here
       });
    });
  }
}
