import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import{Clients}from './clients';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {

  constructor(private http: HttpClient) { }
  GetClients() {
    return this.http.get<any>(`${environment.apiUrl}api/ClientOrganisation/GetClientOrganisation`);
  }
  // GetUser(Id: number) {
  //   return this.http.get<any>(`${environment.apiUrl}api/User/GetUsers/${Id}`);
  // }
  GetClientOrganisationById(Id: number) {
    return this.http.get<any>(`${environment.apiUrl}api/ClientOrganisation/GetClientOrganisationById?OrganisationId=` + Id);
  }
  GetAllActiveCounsellors(){
    return this.http.get<any>(`${environment.apiUrl}api/ClientOrganisation/GetAllActiveCounsellors`);
  }
}
